:root {
    --largeTitle: 34.0px;
    --title1: 29.0px;
    --title2: 22.0px;
    --title3: 20.0px;
    --headline: 17.0px;
    --body: 17.0px;
    --callout: 16.0px;
    --subheadline: 15.0px;
    --footnote: 13.0px;
    --caption1: 12.0px;
    --caption2: 11.0px;

    --font-weight-system-bold: 700;
    --font-weight-system-extra-bold: 800;
    --font-weight-system-extra-light: 200;
    --font-weight-system-light: 300;
    --font-weight-system-medium: 500;
    --font-weight-system-regular: 400;
    --font-weight-system-semibold: 600;

    --max-screen-width: 600px;
}