.feedback-page {

    .sub-title {
        margin-top: 16px;
        margin-left: 16px;
        font-size: var(--footnote);
        color: var(--text-secondary);
    }

    .large-title {
        margin-top: 5px;
        margin-left: 16px;
        font-size: var(--title2);
        color: var(--text-primary);
        font-weight: var(--font-weight-system-semibold);
    }

    .textarea {
        margin-top: 16px;
        margin-left: 16px;
        width: calc(100% - 32px);
        resize: none;
        line-height: var(--subheadline);
        font-size: var(--subheadline);
        padding-top: 10px;
        padding-bottom: 10px;
        height: 64px;
        border-bottom: 1px solid var(--separator);
        height: calc(var(--subheadline) + 20px);
        overflow-x: hidden;
        overflow-y: hidden;
        color: var(--text-primary);
    }

    .limit {
        margin-top: 5px;
        margin-left: 16px;
        font-size: var(--caption2);
        color: var(--text-secondary);
    }

    .next-button {
        margin-top: 32px;
        margin-left: auto;
        margin-right: auto;
        background: var(--system-pink);
        color: var(--system-white);
        font-size: var(--headline);
        border-radius: 22px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: calc(100% - 48px);
        height: 44px;
        font-weight: var(--font-weight-system-semibold);
    }
}