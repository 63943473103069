.edit-page {
    display: flex;
    flex-direction: column;
    min-height: 100%;

    .avatar-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid var(--separator);

        .avatar-main {
            margin-top: 20px;
            align-self: center;
            width: 135px;
            height: 135px;
            position: relative;
            pointer-events: none;

            .selected-avatar {
                position: absolute;
                top: 0;
                width: 135px;
                height: 135px;
                border-radius: 67.5px;
                pointer-events: none;
                object-fit: cover;
            }

            .avatar-camera {
                position: absolute;
                right: 0px;
                bottom: 0px;
                width: 40px;
                height: 40px;
                background-color: var(--tertiary-grouped-background);
                border: 1px solid var(--background);
                border-radius: 22px;
                box-sizing: border-box;

                &::after {
                    display: block;
                    position: absolute;
                    content: '';
                    background-color: var(--text-primary);
                    width: 22px;
                    height: 22px;
                    left: 8px;
                    top: 8px;
                    mask: url(../../../assets/image/camera-22x22@2x.png) no-repeat center/ cover;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        mask: url(../../../assets/image/camera-22x22@3x.png) no-repeat center/ cover;
                    }
                }
            }
        }

        .picker {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
        }

        .avatar-change-button {
            margin-top: 16px;
            margin-bottom: 16px;
            font-size: var(--headline);
            color: var(--system-pink);
            font-weight: var(--font-weight-system-medium);
            pointer-events: none;
        }
    }

    .section-label {
        margin-top: 16px;
        margin-left: 16px;
        font-size: var(--headline);
        color: var(--text-primary);
        font-weight: var(--font-weight-system-semibold);
        margin-bottom: 16px;
    }

    .edit-cell-item {
        height: 70px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;

        &::after {
            display: block;
            position: absolute;
            content: '';
            left: 16px;
            right: 16px;
            height: 1px;
            bottom: 0;
            background-color: var(--separator);
        }

        .icon {
            margin-left: 16px;
            width: 40px;
            height: 40px;
            border-radius: 20px;
            background-color: var(--tertiary-grouped-background);
            position: relative;

            &::after {
                display: block;
                position: absolute;
                width: 22px;
                height: 22px;
                background-color: var(--text-secondary);
                content: '';
                top: 9px;
                left: 9px;
            }

            &.nickname {
                &::after {
                    background-color: var(--text-primary);
                    mask: url(../../../assets/image/personal-nickname-22x22@2x.png) no-repeat center/ cover;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        mask: url(../../../assets/image/personal-nickname-22x22@3x.png) no-repeat center/ cover;
                    }
                }
            }

            &.age {
                &::after {
                    background-color: var(--text-primary);
                    mask: url(../../../assets/image/personal-age-22x22@2x.png) no-repeat center/ cover;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        mask: url(../../../assets/image/personal-age-22x22@3x.png) no-repeat center/ cover;
                    }
                }
            }

            &.about {
                &::after {
                    background-color: var(--text-primary);
                    mask: url(../../../assets/image/personal-about-22x22@2x.png) no-repeat center/ cover;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        mask: url(../../../assets/image/personal-about-22x22@3x.png) no-repeat center/ cover;
                    }
                }
            }

            &.height {
                &::after {
                    mask: url(../../../assets/image/personal-height-22x22@2x.png) no-repeat center/ cover;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        mask: url(../../../assets/image/personal-height-22x22@3x.png) no-repeat center/ cover;
                    }
                }
            }

            &.weight {
                &::after {
                    mask: url(../../../assets/image/personal-weight-22x22@2x.png) no-repeat center/ cover;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        mask: url(../../../assets/image/personal-weight-22x22@3x.png) no-repeat center/ cover;
                    }
                }
            }

            &.hair {
                &::after {
                    mask: url(../../../assets/image/personal-hair-22x22@2x.png) no-repeat center/ cover;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        mask: url(../../../assets/image/personal-hair-22x22@3x.png) no-repeat center/ cover;
                    }
                }
            }


            &.body {
                &::after {
                    mask: url(../../../assets/image/personal-body-22x22@2x.png) no-repeat center/ cover;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        mask: url(../../../assets/image/personal-body-22x22@3x.png) no-repeat center/ cover;
                    }
                }
            }

            &.belong {
                &::after {
                    mask: url(../../../assets/image/personal-belong-22x22@2x.png) no-repeat center/ cover;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        mask: url(../../../assets/image/personal-belong-22x22@3x.png) no-repeat center/ cover;
                    }
                }
            }

            &.constellation {
                &::after {
                    mask: url(../../../assets/image/personal-constellation-22x22@2x.png) no-repeat center/ cover;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        mask: url(../../../assets/image/personal-constellation-22x22@3x.png) no-repeat center/ cover;
                    }
                }
            }
        }

        .title-wrapper {
            height: 40px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .title-label {
                color: var(--text-secondary);
                margin-left: 10px;
                font-size: var(--footnote);
            }

            .content-label {
                color: var(--text-primary);
                margin-left: 10px;
                font-size: var(--subheadline);
                font-weight: var(--font-weight-system-medium);

                &.placeholder {
                    color: var(--text-secondary);
                }
            }

        }

        .arrow {
            width: 10px;
            height: 16px;
            position: absolute;
            right: 16px;
            top: calc((100% - 16px) / 2);
            background-color: var(--text-secondary);
            mask: url(../../../assets/image/arrow-right-10x16@2x.png) no-repeat center/ cover;

            @media screen and (-webkit-min-device-pixel-ratio: 3),
            screen and (min--moz-device-pixel-ratio: 3) {
                mask: url(../../../assets/image/arrow-right-10x16@3x.png) no-repeat center/ cover;
            }
        }
    }
}

.edit-other-page {
    .save-button {
        position: absolute;
        right: 16px;
        color: var(--system-pink);
        font-size: var(--headline);
    }

    .text-wrapper {
        position: relative;

        &::after {
            position: absolute;
            bottom: 0;
            content: '';
            left: 16px;
            right: 16px;
            height: 1px;
            background-color: var(--separator);
        }

        .input {
            color: var(--text-primary);
            margin-top: 16px;
            font-size: var(--subheadline);
            width: calc(100% - 32px);
            margin-left: 16px;
            margin-right: 16px;
            height: calc(var(--subheadline) + 29px);
        }

        .textarea {
            width: calc(100% - 32px);
            margin-top: 16px;
            margin-left: 16px;
            resize: none;
            line-height: var(--subheadline);
            font-size: var(--subheadline);
            padding-top: 14.5px;
            padding-bottom: 14.5px;
            height: calc(var(--subheadline) + 29px);
            overflow-x: hidden;
            overflow-y: hidden;
        }
    }

    .select-wrapper {
        margin: 16px 16px 0 16px;
    }
}