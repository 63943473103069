.profile-page {
    display: flex;
    min-height: 100%;
    flex-direction: column;
    align-items: center;
    padding-top: calc(44px + env(safe-area-inset-top));
    padding-bottom: calc(49px + env(safe-area-inset-bottom));

    .header {
        position: fixed;
        padding-top: env(safe-area-inset-top);
        height: calc(44px + env(safe-area-inset-top));
        width: 100%;
        max-width: var(--max-screen-width);
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        z-index: 1000;
        top: 0;
        background-color: var(--background);

        .title {
            margin-left: 16px;
            font-size: var(--title2);
            font-weight: var(--font-weight-system-semibold);
        }

        .checkin-button {
            margin-right: 16px;
            width: 22px;
            height: 22px;
            background-color: var(--text-primary);
            mask: url(../../../assets/image/checkin-22x22@2x.png) no-repeat center/ 22px 22px;

            @media screen and (-webkit-min-device-pixel-ratio: 3),
            screen and (min--moz-device-pixel-ratio: 3) {
                mask: url(../../../assets/image/checkin-22x22@3x.png) no-repeat center/ 22px 22px;
            }
        }
    }

    .avatar {
        margin-top: 16px;
        width: 75px;
        height: 75px;
        border-radius: 37.5px;
    }

    .nickname-label {
        font-size: var(--headline);
        color: var(--text-primary);
        font-weight: var(--font-weight-system-semibold);
        margin-top: 10px;
    }

    .id-label {
        font-size: var(--footnote);
        color: var(--text-secondary);
        margin-top: 5px;
    }

    .mood-label {
        font-size: var(--footnote);
        color: var(--text-primary);
        margin-top: 10px;
    }

    .email-cell-item {
        margin-top: 16px;
        margin-bottom: 16px;
        height: 70px;
        width: calc(100% - 32px);
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .icon {
            margin-left: 16px;
            width: 48px;
            height: 48px;
            background: url(../../../assets/image/bind-email-58x58@2x.png) no-repeat center/ cover;

            @media screen and (-webkit-min-device-pixel-ratio: 3),
            screen and (min--moz-device-pixel-ratio: 3) {
                background: url(../../../assets/image/bind-email-58x58@3x.png) no-repeat center/ cover;
            }
        }

        .text-label {
            font-size: var(--subheadline);
            color: var(--text-primary);
            margin-left: 16px;
        }

        .arrow {
            width: 10px;
            height: 16px;
            position: absolute;
            right: 16px;
            top: calc((100% - 16px) / 2);
            background-color: var(--text-secondary);
            mask: url(../../../assets/image/arrow-right-10x16@2x.png) no-repeat center/ cover;

            @media screen and (-webkit-min-device-pixel-ratio: 3),
            screen and (min--moz-device-pixel-ratio: 3) {
                mask: url(../../../assets/image/arrow-right-10x16@3x.png) no-repeat center/ cover;
            }
        }

        .badge {
            right: 32px;
            width: 8px;
            height: 8px;
            border-radius: 4px;
            position: absolute;
            background-color: var(--system-red);
            font-size: var(--caption2);
            color: var(--system-white);
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .token-view {
            border-radius: 4px;
            right: 16px;
            position: absolute;
            width: 106px;
            height: 32px;
            background: var(--system-pink);
            border-radius: 16px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            .token-label {
                color: var(--system-white);
                font-size: var(--subheadline);
                font-weight: var(--font-weight-system-semibold);
            }

            .token-icon {
                margin-right: 8px;
                width: 20px;
                height: 20px;
                background: url(../../../assets/image/token-100x100@2x.png) no-repeat center/ cover;

                @media screen and (-webkit-min-device-pixel-ratio: 3),
                screen and (min--moz-device-pixel-ratio: 3) {
                    background: url(../../../assets/image/token-100x100@3x.png) no-repeat center/ cover;
                }
            }

        }
    }

    .profile-cell-item {
        height: 54px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        .icon {
            margin-left: 16px;
            width: 22px;
            height: 22px;
            background-color: var(--text-primary);

            &.email {
                mask: url(../../../assets/image/profile-email-22x22@2x.png) no-repeat center/ cover;

                @media screen and (-webkit-min-device-pixel-ratio: 3),
                screen and (min--moz-device-pixel-ratio: 3) {
                    mask: url(../../../assets/image/profile-email-22x22@3x.png) no-repeat center/ cover;
                }
            }

            &.edit {
                mask: url(../../../assets/image/profile-edit-22x22@2x.png) no-repeat center/ cover;

                @media screen and (-webkit-min-device-pixel-ratio: 3),
                screen and (min--moz-device-pixel-ratio: 3) {
                    mask: url(../../../assets/image/profile-edit-22x22@3x.png) no-repeat center/ cover;
                }
            }

            &.expenses {
                mask: url(../../../assets/image/profile-expenses-22x22@2x.png) no-repeat center/ cover;

                @media screen and (-webkit-min-device-pixel-ratio: 3),
                screen and (min--moz-device-pixel-ratio: 3) {
                    mask: url(../../../assets/image/profile-expenses-22x22@3x.png) no-repeat center/ cover;
                }
            }

            &.backlist {
                mask: url(../../../assets/image/profile-blacklist-22x22@2x.png) no-repeat center/ cover;

                @media screen and (-webkit-min-device-pixel-ratio: 3),
                screen and (min--moz-device-pixel-ratio: 3) {
                    mask: url(../../../assets/image/profile-blacklist-22x22@3x.png) no-repeat center/ cover;
                }
            }

            &.following {
                mask: url(../../../assets/image/profile-following-22x22@2x.png) no-repeat center/ cover;

                @media screen and (-webkit-min-device-pixel-ratio: 3),
                screen and (min--moz-device-pixel-ratio: 3) {
                    mask: url(../../../assets/image/profile-following-22x22@3x.png) no-repeat center/ cover;
                }
            }

            &.follower {
                mask: url(../../../assets/image/profile-follower-22x22@2x.png) no-repeat center/ cover;

                @media screen and (-webkit-min-device-pixel-ratio: 3),
                screen and (min--moz-device-pixel-ratio: 3) {
                    mask: url(../../../assets/image/profile-follower-22x22@3x.png) no-repeat center/ cover;
                }
            }

            &.history {
                mask: url(../../../assets/image/profile-blacklist-22x22@2x.png) no-repeat center/ cover;

                @media screen and (-webkit-min-device-pixel-ratio: 3),
                screen and (min--moz-device-pixel-ratio: 3) {
                    mask: url(../../../assets/image/profile-blacklist-22x22@3x.png) no-repeat center/ cover;
                }
            }

            &.settings {
                mask: url(../../../assets/image/profile-settings-22x22@2x.png) no-repeat center/ cover;

                @media screen and (-webkit-min-device-pixel-ratio: 3),
                screen and (min--moz-device-pixel-ratio: 3) {
                    mask: url(../../../assets/image/profile-settings-22x22@3x.png) no-repeat center/ cover;
                }
            }
        }

        .text-label {
            font-size: var(--subheadline);
            color: var(--text-primary);
            margin-left: 16px;
        }

        .arrow {
            width: 10px;
            height: 16px;
            position: absolute;
            right: 32px;
            top: calc((100% - 16px) / 2);
            background-color: var(--text-secondary);
            mask: url(../../../assets/image/arrow-right-10x16@2x.png) no-repeat center/ cover;

            @media screen and (-webkit-min-device-pixel-ratio: 3),
            screen and (min--moz-device-pixel-ratio: 3) {
                mask: url(../../../assets/image/arrow-right-10x16@3x.png) no-repeat center/ cover;
            }
        }
    }
}