.first-recharge-button {
    position: fixed;
    bottom: calc(env(safe-area-inset-bottom) + 49px);
    z-index: 1000;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 114px;
    height: 114px;
    margin: 0 auto;
    margin-left: calc(min(100vw, var(--max-screen-width)) - 100px - 16px);

    // .time-label-container {
    //     position: absolute;
    //     z-index: 1001;
    //     bottom: 10px;
    //     width: 100%;
    //     height: 20px;
    //     text-align: center;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;

    //     .time-label {
    //         font-size: 9px;
    //         color: var(--system-white);
    //         background: linear-gradient(180deg, #FF8E95 0%, #FF3B2E 100%);
    //         border-radius: 10px;
    //         padding-left: 5px;
    //         padding-right: 5px;
    //         line-height: 20px;
    //     }
    // }
}