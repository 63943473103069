.checkin-modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1001;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s ease-in-out;

    &.hide {
        opacity: 0;
        pointer-events: none;

        .content-view {
            transform: translateY(20px)
        }
    }

    .mask {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: var(--dialog-mask);
        z-index: 1;
    }

    .content-view {
        z-index: 2;
        background-color: var(--background);
        width: calc(100% - 32px);
        max-width: calc(375px - 32px);
        border-radius: 6px;
        overflow: hidden;
        transition: all .2s ease-in-out;

        .header {
            background: linear-gradient(180deg, #FF2D55 0%, #FF9500 100%);
            border-radius: 6px 6px 0px 0px;
            position: relative;
            padding-top: 15px;
            padding-bottom: 10px;

            .title {
                font-size: var(--title2);
                color: var(--system-white);
                font-weight: var(--font-weight-system-semibold);
                text-align: center;
            }

            .sub-title {
                margin-top: 10px;
                font-size: var(--footnote);
                color: var(--system-white);
                font-weight: var(--font-weight-system-regular);
                text-align: center;
            }

            .close-button {
                width: 22px;
                height: 22px;
                position: absolute;
                top: 17px;
                right: 16px;
                background: url(../../assets/image/close-opacity-22x22@2x.png) no-repeat center/ cover;

                @media screen and (-webkit-min-device-pixel-ratio: 3),
                screen and (min--moz-device-pixel-ratio: 3) {
                    background: url(../../assets/image/close-opacity-22x22@3x.png) no-repeat center/ cover;
                }
            }
        }

        .main-container {
            background-color: var(--secondary-background);
            padding-bottom: 20px;

            .list-view {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                padding-top: 10px;

                .item {
                    width: calc((100% - 56px) / 4);
                    height: 80px;
                    background: var(--background);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 8px;
                    margin-bottom: 8px;
                    border-radius: 6px;
                    position: relative;
                    overflow: hidden;
                    flex-direction: column;

                    &:nth-child(4n + 1) {
                        margin-left: 16px;
                    }

                    &.active {
                        background: linear-gradient(180deg, #FE055A 0%, #FF7C04 100%);

                        .label {
                            color: var(--system-white);
                        }
                    }

                    &.checked {
                        opacity: 0.5;

                        &::after {
                            position: absolute;
                            content: '';
                            width: 38px;
                            height: 38px;
                            background: url(../../assets/image/checkin-complete-38x38@2x.png) no-repeat center/ cover;

                            @media screen and (-webkit-min-device-pixel-ratio: 3),
                            screen and (min--moz-device-pixel-ratio: 3) {
                                background: url(../../assets/image/checkin-complete-38x38@3x.png) no-repeat center/ cover;
                            }
                        }
                    }

                    .index-badge {
                        position: absolute;
                        top: 0px;
                        left: -26.5px;
                        width: 0;
                        height: 0;
                        border: 26.5px solid transparent;
                        border-top-color: #9465F1;
                    }

                    .index-label {
                        position: absolute;
                        top: 2px;
                        left: 2px;
                        font-size: 9px;
                        color: var(--system-white);
                        line-height: 11px;
                    }

                    .token {
                        width: 30px;
                        height: 30px;
                        background: url(../../assets/image/token-100x100@2x.png) no-repeat center/ cover;

                        @media screen and (-webkit-min-device-pixel-ratio: 3),
                        screen and (min--moz-device-pixel-ratio: 3) {
                            background: url(../../assets/image/token-100x100@3x.png) no-repeat center/ cover;
                        }
                    }

                    .label {
                        margin-top: 9px;
                        font-size: var(--footnote);
                        color: var(--text-primary);
                    }
                }
            }

            .checkin-button {
                margin: 16px auto 0;
                width: 292px;
                height: 44px;
                border-radius: 21px;
                background-color: var(--system-pink);
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: var(--headline);
                color: var(--system-white);
                font-weight: var(--font-weight-system-medium);
            }
        }
    }
}