.login-page {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    align-items: center;

    .login-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 16px;

        .input-wrapper {
            position: relative;
            height: 44px;
            width: calc(100% - 48px);
            border-radius: 6px;
            border: 1px solid var(--text-quaternary);
            line-height: 44px;

            &.email {
                margin-top: 16px;
            }

            &.password {
                margin-top: 16px;
            }

            .input {
                color: var(--text-primary);
                position: absolute;
                padding: 0 16px 0 54px;
                width: 100%;
                height: 100%;
                font-size: var(--subheadline);
            }

            .icon {
                display: block;
                content: '';
                top: 11px;
                left: 16px;
                width: 22px;
                height: 22px;
                position: absolute;
                background-color: var(--text-secondary);

                &.email {
                    mask: url(../../../assets/image/accounts-message-22x22@2x.png) no-repeat center/ cover;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        mask: url(../../../assets/image/accounts-message-22x22@3x.png) no-repeat center/ cover;
                    }
                }

                &.password {
                    mask: url(../../../assets/image/accounts-lock-22x22@2x.png) no-repeat center/ cover;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        mask: url(../../../assets/image/accounts-lock-22x22@3x.png) no-repeat center/ cover;
                    }
                }
            }
        }
    }

    .login-button {
        margin-top: 32px;
        background: var(--system-pink);
        color: var(--system-white);
        font-size: var(--headline);
        border-radius: 22px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: calc(100% - 48px);
        height: 44px;
        font-weight: var(--font-weight-system-semibold);
    }

    .toggle-label {
        margin-top: 32px;
        margin-bottom: 16px;
        font-size: var(--caption2);
        margin-left: 16px;
        margin-right: 16px;
        text-align: center;

        .underline {
            text-decoration: underline;
        }
    }
}