.detail-page {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    padding-bottom: calc(112px + env(safe-area-inset-bottom));

    .page-state {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .more-button {
        width: 22px;
        height: 22px;
        right: 16px;
        position: absolute;
        background-color: var(--text-primary);
        mask: url(../../../assets/image/more-22x22@2x.png) no-repeat center/ 100%;

        @media screen and (-webkit-min-device-pixel-ratio: 3),
        screen and (min--moz-device-pixel-ratio: 3) {
            mask: url(../../../assets/image/more-22x22@3x.png) no-repeat center/ 100%;
        }

    }

    .banner {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 133%;
        // background-color: var(--system-black);

        .page-indicator {
            position: absolute;
            right: 16px;
            top: 16px;
            padding: 7px 11px;
            border-radius: 14px;
            background: rgba(0, 0, 0, 0.6);
            z-index: 2;
            color: var(--system-white);
            font-size: var(--caption1);
        }

        .content-view {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            z-index: 1;

            .banner-item {
                flex-shrink: 0;
                width: 100%;
                height: 100%;
                background-color: var(--tertiary-grouped-background);

                .image {
                    width: 100%;
                    height: 100%;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                }
            }
        }
    }

    .banner-avatar {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 100%;

        .banner-avatar-container {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            display: flex;
        }

        .avatar {
            width: 100%;
            height: 100%;
        }
    }

    .headline {
        display: flex;
        flex-direction: column;
        padding-bottom: 16px;
        border-bottom: 1px solid var(--separator);
        position: relative;

        .section-1 {
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;

            .avatar {
                margin-top: 16px;
                margin-left: 16px;
                width: 45px;
                height: 45px;
                border-radius: 22.5px;
            }

            .text-container {
                margin-top: 16px;
                height: 45px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 10px;

                .nickname-label {
                    color: var(--text-primary);
                    font-size: var(--headline);
                    font-weight: var(--font-weight-system-medium);
                }

                .id-label {
                    margin-top: 5px;
                    color: var(--text-secondary);
                    font-size: var(--footnote);
                }
            }

            .price-view {
                position: absolute;
                right: 16px;
                display: flex;
                flex-direction: row;
                align-items: center;

                .price-image {
                    width: 22px;
                    height: 22px;
                    margin-right: 5px;
                    background: url(../../../assets/image/token-100x100@2x.png) no-repeat center/ 100%;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        background: url(../../../assets/image/token-100x100@3x.png) no-repeat center/ 100%;
                    }
                }

                .price-label {
                    color: var(--system-orange);
                    font-size: var(--caption2);
                    font-weight: var(--font-weight-system-medium);
                }
            }

        }


        .section-2 {
            margin-top: 10px;
            margin-left: 16px;
            display: flex;
            flex-direction: row;
            align-items: center;

            .star {
                width: 12px;
                height: 12px;
                margin-right: 5px;

                background: url(../../../assets/image/star-fill-22x22@2x.png) no-repeat center/ 100%;

                @media screen and (-webkit-min-device-pixel-ratio: 3),
                screen and (min--moz-device-pixel-ratio: 3) {
                    background: url(../../../assets/image/star-fill-22x22@3x.png) no-repeat center/ 100%;
                }

                &.unfill {
                    background: url(../../../assets/image/star-unfill-22x22@2x.png) no-repeat center/ 100%;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        background: url(../../../assets/image/star-unfill-22x22@3x.png) no-repeat center/ 100%;
                    }
                }
            }

            .star-label {
                color: var(--system-yellow);
                font-size: var(--footnote);
                font-weight: var(--font-weight-system-medium);
            }
        }

        .section-3 {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 10px;

            .label {
                color: var(--text-primary);
                font-size: var(--subheadline);
                font-weight: var(--font-weight-system-medium);
            }

            .message-button {
                margin-left: 16px;
                width: calc(((100% - 42px) / 5) * 3);
                height: 36px;
                border-radius: 18px;
                background-color: var(--tertiary-grouped-background);
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                .message-image {
                    margin-right: 5px;
                    width: 15px;
                    height: 14px;
                    background-color: var(--text-primary);
                    mask: url(../../../assets/image/message-button-15x14@2x.png) no-repeat center/ 100%;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        mask: url(../../../assets/image/message-button-15x14@3x.png) no-repeat center/ 100%;
                    }
                }
            }

            .follow-button {
                margin-left: 10px;
                width: calc(((100% - 42px) / 5) * 2);
                height: 36px;
                border-radius: 18px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                border: 1px solid var(--separator);

                .follow-image {
                    margin-right: 5px;
                    width: 15px;
                    height: 14px;
                    background: url(../../../assets/image/add-14x14@2x.png) no-repeat center/ 100%;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        background: url(../../../assets/image/add-14x14@3x.png) no-repeat center/ 100%;
                    }
                }
            }
        }
    }

    .about {
        border-bottom: 1px solid var(--separator);
        padding-bottom: 15px;
        position: relative;

        .about-title {
            margin-left: 16px;
            margin-top: 16px;
            color: var(--text-primary);
            font-size: var(--headline);
            font-weight: var(--font-weight-system-semibold);
        }

        .about-label {
            margin-left: 16px;
            margin-top: 16px;
            color: var(--text-primary);
            font-size: var(--subheadline);
            font-weight: var(--font-weight-system-regular);
        }
    }

    .rank {
        border-bottom: 1px solid var(--separator);
        padding-bottom: 15px;
        position: relative;

        .rank-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            height: 44px;

            .rank-title {
                margin-left: 16px;
                color: var(--text-primary);
                font-size: var(--headline);
                font-weight: var(--font-weight-system-semibold);
            }

            .rank-more-button {
                display: flex;
                flex-direction: row;
                align-items: center;

                .rank-move-label {
                    color: var(--text-secondary);
                    font-size: var(--caption1);
                }

                .rank-move-arrow {
                    margin-left: 4px;
                    margin-right: 16px;
                    width: 10px;
                    height: 16px;
                    background-color: var(--text-secondary);
                    mask: url(../../../assets/image/arrow-right-10x16@2x.png) no-repeat center/ 100%;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        mask: url(../../../assets/image/arrow-right-10x16@3x.png) no-repeat center/ 100%;
                    }
                }
            }
        }

        .rank-container {
            margin-top: 8px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            .rank-container-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .rank-top {
                    width: 28px;
                    height: 28px;
                    z-index: 2;

                    &.top1 {
                        background: url(../../../assets/image/ranking-top1-28x28@2x.png) no-repeat center/ 100%;

                        @media screen and (-webkit-min-device-pixel-ratio: 3),
                        screen and (min--moz-device-pixel-ratio: 3) {
                            background: url(../../../assets/image/ranking-top1-28x28@3x.png) no-repeat center/ 100%;
                        }
                    }


                    &.top2 {
                        background: url(../../../assets/image/ranking-top2-28x28@2x.png) no-repeat center/ 100%;

                        @media screen and (-webkit-min-device-pixel-ratio: 3),
                        screen and (min--moz-device-pixel-ratio: 3) {
                            background: url(../../../assets/image/ranking-top2-28x28@3x.png) no-repeat center/ 100%;
                        }
                    }


                    &.top3 {
                        background: url(../../../assets/image/ranking-top3-28x28@2x.png) no-repeat center/ 100%;

                        @media screen and (-webkit-min-device-pixel-ratio: 3),
                        screen and (min--moz-device-pixel-ratio: 3) {
                            background: url(../../../assets/image/ranking-top3-28x28@3x.png) no-repeat center/ 100%;
                        }
                    }
                }

                .rank-avatar {
                    margin-left: 32px;
                    margin-right: 32px;
                    margin-top: -8px;
                    width: 54px;
                    height: 54px;
                    border-radius: 27px;
                    box-sizing: border-box;
                    padding: 2px;

                    &.top1 {
                        background-image: linear-gradient(360deg, rgba(255, 173, 43, 1), rgba(254, 234, 2, 1));
                    }

                    &.top2 {
                        background-image: linear-gradient(180deg, rgba(120, 224, 255, 1), rgba(12, 108, 241, 1));
                    }

                    &.top3 {
                        background-image: linear-gradient(180deg, rgba(255, 203, 164, 1), rgba(255, 100, 80, 1));
                    }

                    .rank-container-item-main {
                        width: 100%;
                        height: 100%;
                        border-radius: 27px;

                        .rank-container-item-avatar {
                            border-radius: 27px;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

                .rank-label {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    margin-top: 10px;
                    color: var(--text-primary);
                    font-size: var(--footnote);

                    &::before {
                        content: '';
                        display: block;
                        width: 14px;
                        height: 12px;
                        margin-right: 4px;
                        background: url(../../../assets/image/ranking-lover-14x12@2x.png) no-repeat center/ 100%;

                        @media screen and (-webkit-min-device-pixel-ratio: 3),
                        screen and (min--moz-device-pixel-ratio: 3) {
                            background: url(../../../assets/image/ranking-lover-14x12@3x.png) no-repeat center/ 100%;
                        }
                    }
                }
            }
        }
    }

    .videos {
        border-bottom: 1px solid var(--separator);
        padding-bottom: 15px;
        position: relative;

        .videos-title {
            margin-left: 16px;
            margin-top: 16px;
            color: var(--text-primary);
            font-size: var(--headline);
            font-weight: var(--font-weight-system-semibold);
        }

        .video-list {
            padding-top: 16px;
            padding-bottom: 16px;
            padding-left: 16px;
            display: flex;
            flex-direction: row;
            overflow-x: scroll;

            .video-list-item {
                flex-shrink: 0;
                width: 109px;
                height: 145px;
                border-radius: 6px;
                background-color: #000000;
                margin-right: 8px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                overflow: hidden;

                .video {
                    width: 109px;
                    height: 145px;
                }
            }
        }

    }

    .personal {
        .personal-title {
            margin-left: 16px;
            margin-top: 16px;
            color: var(--text-primary);
            font-size: var(--headline);
            font-weight: var(--font-weight-system-semibold);
        }

        .personal-list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding-top: 5px;
            padding-bottom: 5px;

            .personal-item-view {
                flex-shrink: 0;
                width: 50%;
                display: flex;
                flex-direction: row;
                margin-top: 5px;
                margin-bottom: 5px;
                align-items: center;

                .item-icon {
                    margin-left: 16px;
                    margin-right: 5px;
                    width: 22px;
                    height: 22px;
                    background-color: var(--text-secondary);

                    &.age {
                        background-color: var(--text-primary);
                        mask: url(../../../assets/image/personal-age-22x22@2x.png) no-repeat center/ cover;

                        @media screen and (-webkit-min-device-pixel-ratio: 3),
                        screen and (min--moz-device-pixel-ratio: 3) {
                            mask: url(../../../assets/image/personal-age-22x22@3x.png) no-repeat center/ cover;
                        }
                    }

                    &.gender-female {
                        background-color: var(--text-primary);
                        mask: url(../../../assets/image/gender-female-22x22@2x.png) no-repeat center/ cover;

                        @media screen and (-webkit-min-device-pixel-ratio: 3),
                        screen and (min--moz-device-pixel-ratio: 3) {
                            mask: url(../../../assets/image/gender-female-22x22@3x.png) no-repeat center/ cover;
                        }
                    }

                    &.gender-male {
                        background-color: var(--text-primary);
                        mask: url(../../../assets/image/gender-male-22x22@2x.png) no-repeat center/ cover;

                        @media screen and (-webkit-min-device-pixel-ratio: 3),
                        screen and (min--moz-device-pixel-ratio: 3) {
                            mask: url(../../../assets/image/gender-male-22x22@3x.png) no-repeat center/ cover;
                        }
                    }

                    &.height {
                        mask: url(../../../assets/image/personal-height-22x22@2x.png) no-repeat center/ cover;

                        @media screen and (-webkit-min-device-pixel-ratio: 3),
                        screen and (min--moz-device-pixel-ratio: 3) {
                            mask: url(../../../assets/image/personal-height-22x22@3x.png) no-repeat center/ cover;
                        }
                    }

                    &.weight {
                        mask: url(../../../assets/image/personal-weight-22x22@2x.png) no-repeat center/ cover;

                        @media screen and (-webkit-min-device-pixel-ratio: 3),
                        screen and (min--moz-device-pixel-ratio: 3) {
                            mask: url(../../../assets/image/personal-weight-22x22@3x.png) no-repeat center/ cover;
                        }
                    }

                    &.hair {
                        mask: url(../../../assets/image/personal-hair-22x22@2x.png) no-repeat center/ cover;

                        @media screen and (-webkit-min-device-pixel-ratio: 3),
                        screen and (min--moz-device-pixel-ratio: 3) {
                            mask: url(../../../assets/image/personal-hair-22x22@3x.png) no-repeat center/ cover;
                        }
                    }

                    &.body {
                        mask: url(../../../assets/image/personal-body-22x22@2x.png) no-repeat center/ cover;

                        @media screen and (-webkit-min-device-pixel-ratio: 3),
                        screen and (min--moz-device-pixel-ratio: 3) {
                            mask: url(../../../assets/image/personal-body-22x22@3x.png) no-repeat center/ cover;
                        }
                    }

                    &.belong {
                        mask: url(../../../assets/image/personal-belong-22x22@2x.png) no-repeat center/ cover;

                        @media screen and (-webkit-min-device-pixel-ratio: 3),
                        screen and (min--moz-device-pixel-ratio: 3) {
                            mask: url(../../../assets/image/personal-belong-22x22@3x.png) no-repeat center/ cover;
                        }
                    }

                    &.constellation {
                        mask: url(../../../assets/image/personal-constellation-22x22@2x.png) no-repeat center/ cover;

                        @media screen and (-webkit-min-device-pixel-ratio: 3),
                        screen and (min--moz-device-pixel-ratio: 3) {
                            mask: url(../../../assets/image/personal-constellation-22x22@3x.png) no-repeat center/ cover;
                        }
                    }
                }

                .item-label {
                    color: var(--text-primary);
                    font-size: var(--subheadline);
                    font-weight: var(--font-weight-system-regular);
                }
            }
        }
    }

    .footer-view {
        position: fixed;
        z-index: 3;
        left: 0;
        right: 0;
        bottom: env(safe-area-inset-bottom);
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        max-width: var(--max-screen-width);

        .content-view {
            margin: 0 auto;
            width: calc(100% - 32px);
            height: 44px;
            box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
            border-radius: 25px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            &.online {
                position: relative;
                background: linear-gradient(310deg, #12AC39 0%, #34C759 52%, #C8E700 100%);

                &:before {
                    content: "";
                    position: absolute;
                    width: 200px;
                    height: 30px;
                    background-color: rgba(255, 255, 255, 0.5);
                    transform: rotate(-45deg);
                    left: calc(0% - 200px);
                    animation: blink 2s ease-in-out 2s infinite;
                }

                @keyframes blink {
                    from {
                        left: calc(0% - 200px);
                        top: 0;
                    }

                    to {
                        left: 100%;
                        top: 0;
                    }
                }
            }

            &.offline {
                background: var(--system-grey3);
            }

            &.busy {
                background: var(--system-orange);
            }

            .icon {
                width: 23px;
                height: 14px;
                background-color: var(--system-white);
                margin-right: 5px;
                mask: url(../../../assets/image/video-call-23x14@2x.png) no-repeat center/ cover;

                @media screen and (-webkit-min-device-pixel-ratio: 3),
                screen and (min--moz-device-pixel-ratio: 3) {
                    mask: url(../../../assets/image/video-call-23x14@3x.png) no-repeat center/ cover;
                }
            }

            .label {
                color: var(--system-white);
                font-size: var(--subheadline);
                font-weight: var(--font-weight-system-semibold);
            }
        }
    }
}