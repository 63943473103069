.signup-page {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    align-items: center;

    .step {
        display: flex;
        flex-direction: column;
        width: calc(100% - 48px);

        .index-title {
            margin-top: 16px;
            font-size: var(--caption1);
            color: var(--text-secondary);
        }

        .title {
            margin-top: 16px;
            font-size: var(--headline);
            color: var(--text-primary);
            font-weight: var(--font-weight-system-semibold);
        }

        .toggle-label {
            margin-top: 32px;
            margin-bottom: 16px;
            font-size: var(--caption2);
            margin-left: 16px;
            margin-right: 16px;
            text-align: center;

            .underline {
                text-decoration: underline;
            }
        }

        .signup-form {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            .input-wrapper {
                position: relative;
                height: 44px;
                width: 100%;
                border-radius: 6px;
                border: 1px solid var(--text-quaternary);
                line-height: 44px;
                margin-top: 16px;

                .input {
                    position: absolute;
                    padding: 0 16px 0 16px;
                    width: 100%;
                    height: 100%;
                    color: var(--text-primary);
                    font-size: var(--subheadline);

                    &.with-icon {
                        padding: 0 16px 0 54px;
                    }
                }

                .icon {
                    display: block;
                    content: '';
                    top: 11px;
                    left: 16px;
                    width: 22px;
                    height: 22px;
                    position: absolute;
                    background-color: var(--text-secondary);

                    &.email {
                        mask: url(../../../assets/image/accounts-message-22x22@2x.png) no-repeat center/ cover;

                        @media screen and (-webkit-min-device-pixel-ratio: 3),
                        screen and (min--moz-device-pixel-ratio: 3) {
                            mask: url(../../../assets/image/accounts-message-22x22@3x.png) no-repeat center/ cover;
                        }
                    }

                    &.password {
                        mask: url(../../../assets/image/accounts-lock-22x22@2x.png) no-repeat center/ cover;

                        @media screen and (-webkit-min-device-pixel-ratio: 3),
                        screen and (min--moz-device-pixel-ratio: 3) {
                            mask: url(../../../assets/image/accounts-lock-22x22@3x.png) no-repeat center/ cover;
                        }
                    }
                }
            }
        }

        .select-wrapper {
            margin-top: 16px;
        }

        .select-warpper {
            display: flex;
            flex-direction: row;

            .select-wrapper {
                width: calc((100% - 16px) / 2);

                &:nth-child(1) {
                    margin-right: 16px;
                }
            }
        }

        .signup-button {
            margin-top: 32px;
            background: var(--system-pink);
            color: var(--system-white);
            font-size: var(--headline);
            border-radius: 22px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 44px;
            font-weight: var(--font-weight-system-semibold);
        }

        .avatar-button {
            margin-top: 32px;
            align-self: center;
            width: 144px;
            height: 164px;
            position: relative;

            .picker {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 0;
            }

            .selected-avatar {
                position: absolute;
                top: 0;
                width: 144px;
                height: 144px;
                border-radius: 72px;
                pointer-events: none;
                object-fit: cover;
            }

            .default-avatar {
                align-self: center;
                width: 144px;
                height: 144px;
                border-radius: 72px;
                background: url(../../../assets/image/default-avatar-1024x1024.png) no-repeat center/ cover;
            }

            .avatar-camera {
                position: absolute;
                left: calc((100% - 40px)/2);
                bottom: 0px;
                width: 40px;
                height: 40px;
                background-color: var(--tertiary-grouped-background);
                border: 1px solid var(--background);
                border-radius: 22px;
                box-sizing: border-box;

                &::after {
                    display: block;
                    position: absolute;
                    content: '';
                    background-color: var(--text-primary);
                    width: 22px;
                    height: 22px;
                    left: 8px;
                    top: 8px;
                    mask: url(../../../assets/image/camera-22x22@2x.png) no-repeat center/ cover;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        mask: url(../../../assets/image/camera-22x22@3x.png) no-repeat center/ cover;
                    }
                }
            }
        }
    }
}