.rating-page {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    align-items: center;
    position: relative;

    .close-button {
        width: 22px;
        height: 22px;
        position: absolute;
        top: calc(env(safe-area-inset-top) + 11px);
        right: 16px;
        background: url(../../../assets/image/close-around-22x22@2x.png) no-repeat center/ 100%;

        @media screen and (-webkit-min-device-pixel-ratio: 3),
        screen and (min--moz-device-pixel-ratio: 3) {
            background: url(../../../assets/image/close-around-22x22@3x.png) no-repeat center/ 100%;
        }
    }

    .title-label {
        margin-top: calc(env(safe-area-inset-top) + 44px);
        font-size: var(--title2);
        color: var(--text-primary);
        font-weight: var(--font-weight-system-semibold);
    }

    .desc-label {
        margin-top: 10px;
        font-size: var(--subheadline);
        color: var(--system-pink);
        font-weight: var(--font-weight-system-medium);
    }

    .conversation-info {
        display: flex;
        flex-direction: column;
        width: calc(100% - 32px);
        margin-top: 87.5px;
        align-items: center;
        border: 1px solid var(--separator);
        border-top: 9;
        border-radius: 6px;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            width: 115px;
            height: 115px;
            border: 1px solid var(--separator);
            border-radius: 57.5px;
            top: -57.5px;
            z-index: 1;
            background-color: var(--background);
        }

        .avatar {
            width: 85px;
            height: 85px;
            border-radius: 42.5px;
            z-index: 2;
            position: absolute;
            top: -42.5px;
        }

        .text-wrapper {
            margin-left: 10px;
        }

        .nickname-label {
            font-size: var(--subheadline);
            color: var(--text-primary);
            font-weight: var(--font-weight-system-medium);
        }
    }

    .rating-title {
        width: calc(100% - 72px);
        text-align: center;
        font-size: var(--headline);
        color: var(--text-primary);
        font-weight: var(--font-weight-system-medium);
        margin-top: 85px;
    }

    .ratings-wrapper {
        margin-top: 10px;
        display: flex;
        width: calc(100% - 72px);
        flex-direction: row;
        padding-bottom: 11px;
        border-bottom: 1px solid var(--separator);
        align-items: center;
        justify-content: center;

        .rate-item {
            cursor: pointer;
            padding: 5px;
            width: 56px;
            height: 56px;
            background: url(../../../assets/image/star-unfill-36x36@2x.png) no-repeat center/ 36px 36px;

            @media screen and (-webkit-min-device-pixel-ratio: 3),
            screen and (min--moz-device-pixel-ratio: 3) {
                background: url(../../../assets/image/star-unfill-36x36@3x.png) no-repeat center/ 36px 36px;
            }

            &.fill {
                background: url(../../../assets/image/star-fill-36x36@2x.png) no-repeat center/ 36px 36px;

                @media screen and (-webkit-min-device-pixel-ratio: 3),
                screen and (min--moz-device-pixel-ratio: 3) {
                    background: url(../../../assets/image/star-fill-36x36@3x.png) no-repeat center/ 36px 36px;
                }
            }
        }
    }

    .textarea {
        width: calc(100% - 70px);
        resize: none;
        line-height: var(--subheadline);
        font-size: var(--subheadline);
        overflow-x: hidden;
        overflow-y: hidden;
        height: 102px;
        background-color: var(--background-light);
        margin-top: 20px;
        margin-bottom: 10px;
        padding: 10px;
    }

    .next-button {
        margin-top: 36px;
        background: var(--system-pink);
        color: var(--system-white);
        font-size: var(--headline);
        border-radius: 22px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: calc(100% - 70px);
        height: 44px;
        font-weight: var(--font-weight-system-semibold);
    }

}