.conversation-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--system-black);
    position: relative;
    min-height: 100%;

    ::-webkit-scrollbar {
        display: none;
    }

    .swipe {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        flex-shrink: 0;
        flex-wrap: nowrap;
        overflow-x: scroll;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        .view-page {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            width: 100%;
            height: 100%;
            flex-shrink: 0;
        }

        .main {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            width: 100%;
            height: 100%;
            flex-shrink: 0;
        }

    }

    .large-video-view {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
    }

    .disabled-video-camera {
        z-index: 2;
        position: absolute;
        width: 22px;
        height: 22px;
        background: url(../../../assets/image/videotape-disabled-22x22@2x.png) no-repeat center/ 100%;

        @media screen and (-webkit-min-device-pixel-ratio: 3),
        screen and (min--moz-device-pixel-ratio: 3) {
            background: url(../../../assets/image/videotape-disabled-22x22@3x.png) no-repeat center/ 100%;
        }
    }

    .small-video-view {
        width: 94px;
        height: 130px;
        background-color: rgba(44, 44, 44, 1);
        top: calc(54px + env(safe-area-inset-top));
        right: 16px;
        position: absolute;
        border-radius: 6px;
        overflow: hidden;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
    }

    .reverse-button {
        width: 94px;
        height: 130px;
        top: 54px;
        right: 16px;
        position: absolute;
        border-radius: 6px;
        overflow: hidden;
        z-index: 2;
    }

    .close-button {
        position: absolute;
        z-index: 2;
        right: 16px;
        top: calc(16px + env(safe-area-inset-top));
        width: 22px;
        height: 22px;
        background: url(../../../assets/image/close-shadow-22x22@2x.png) no-repeat center/ 100%;

        @media screen and (-webkit-min-device-pixel-ratio: 3),
        screen and (min--moz-device-pixel-ratio: 3) {
            background: url(../../../assets/image/close-shadow-22x22@3x.png) no-repeat center/ 100%;
        }
    }

    .header {
        position: absolute;
        left: 16px;
        top: calc(16px + env(safe-area-inset-top));
        height: 34px;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 17px;
        z-index: 2;
        display: flex;
        flex-direction: row;
        align-items: center;

        .avatar {
            margin-left: 3px;
            width: 28px;
            height: 28px;
            border-radius: 14px;
        }

        .text-wrapper {
            margin-left: 7.5px;
            margin-right: 10px;
            height: 28px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .nickname {
                font-size: var(--footnote);
                color: var(--system-white);
                line-height: var(--footnote);
            }

            .duration {
                font-size: var(--caption2);
                color: var(--system-white);
                line-height: var(--caption2);
            }
        }

        .follow-button {
            margin-right: 6px;
            width: 29px;
            height: 22px;
            background: url(../../../assets/image/follow-29x22@2x.png) no-repeat center/ 100%;

            @media screen and (-webkit-min-device-pixel-ratio: 3),
            screen and (min--moz-device-pixel-ratio: 3) {
                background: url(../../../assets/image/follow-29x22@3x.png) no-repeat center/ 100%;
            }

            &.followed {
                pointer-events: none;
                background: url(../../../assets/image/followed-29x22@2x.png) no-repeat center/ 100%;

                @media screen and (-webkit-min-device-pixel-ratio: 3),
                screen and (min--moz-device-pixel-ratio: 3) {
                    background: url(../../../assets/image/followed-29x22@3x.png) no-repeat center/ 100%;
                }
            }
        }
    }

    .feature-buttons {
        height: 49px;
        top: calc(184px + env(safe-area-inset-top));
        right: 16px;
        position: absolute;
        border-radius: 6px;
        overflow: hidden;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;

        .camera-button {
            width: 34px;
            height: 34px;
            border-radius: 17px;
            background: rgba(0, 0, 0, 0.3);
            position: relative;
            margin-right: 14px;

            &.videotape::after {
                content: '';
                position: absolute;
                width: 22px;
                height: 22px;
                left: 6px;
                top: 6px;
                background: url(../../../assets/image/videotape-22x22@2x.png) no-repeat center/ 100%;

                @media screen and (-webkit-min-device-pixel-ratio: 3),
                screen and (min--moz-device-pixel-ratio: 3) {
                    background: url(../../../assets/image/videotape-22x22@3x.png) no-repeat center/ 100%;
                }
            }

            &.videotape-disabled::after {
                content: '';
                position: absolute;
                width: 22px;
                height: 22px;
                left: 6px;
                top: 6px;
                background: url(../../../assets/image/videotape-disabled-22x22@2x.png) no-repeat center/ 100%;

                @media screen and (-webkit-min-device-pixel-ratio: 3),
                screen and (min--moz-device-pixel-ratio: 3) {
                    background: url(../../../assets/image/videotape-disabled-22x22@3x.png) no-repeat center/ 100%;
                }
            }
        }

        .toggle-button {
            width: 34px;
            height: 34px;
            border-radius: 17px;
            background: rgba(0, 0, 0, 0.3);
            position: relative;

            &::after {
                content: '';
                position: absolute;
                width: 22px;
                height: 22px;
                left: 6px;
                top: 6px;
                background: url(../../../assets/image/toggle-22x22@2x.png) no-repeat center/ 100%;

                @media screen and (-webkit-min-device-pixel-ratio: 3),
                screen and (min--moz-device-pixel-ratio: 3) {
                    background: url(../../../assets/image/toggle-22x22@3x.png) no-repeat center/ 100%;
                }
            }
        }
    }

    .tips-container {
        bottom: calc(81px + env(safe-area-inset-bottom));
        left: 0;
        right: 0;
        width: 100%;
        position: absolute;
        align-items: center;
        justify-content: center;
        display: flex;
        align-items: center;
        justify-content: center;

        .tips {
            z-index: 3;
            height: 36px;
            border-radius: 18px;
            background: #AF52DE;
            opacity: 0.9;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 16px;
            padding-right: 46px;
            animation: tips-animation 3s ease-in-out infinite;

            @keyframes tips-animation {
                0% {
                    transform: translateY(0px);
                }

                50% {
                    transform: translateY(-6px);
                }

                100% {
                    transform: translateY(0px);
                }
            }

            .tips-label {
                font-size: var(--footnote);
                font-weight: var(--font-weight-system-medium);
                color: #FFFFFF;
            }

            &::after {
                display: block;
                content: '';
                position: absolute;
                bottom: -12px;
                width: 0;
                height: 0;
                border-top: 12px solid #AF52DE;
                border-right: 12px solid transparent;
                border-bottom: 12px solidt ransparent;
                border-left: 12px solid transparent;
            }

            .tips-close-button {
                position: absolute;
                right: 16px;
                width: 20px;
                height: 20px;
                background: url(../../../assets/image/close-opacity-22x22@2x.png) no-repeat center/ 100%;

                @media screen and (-webkit-min-device-pixel-ratio: 3),
                screen and (min--moz-device-pixel-ratio: 3) {
                    background: url(../../../assets/image/close-opacity-22x22@3x.png) no-repeat center/ 100%;
                }
            }
        }
    }

    .footer {
        position: absolute;
        left: 16px;
        right: 16px;
        bottom: 8px;
        height: calc(49px + env(safe-area-inset-bottom));
        padding-bottom: env(safe-area-inset-bottom);
        z-index: 2;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .left {
            .textField {
                height: 34px;
                background: rgba(0, 0, 0, 0.3);
                border-radius: 17px;
                display: flex;
                align-items: center;
                padding: 0 16px;
                font-size: var(--footnote);
                color: var(--system-white);
            }
        }

        .count-down-view {
            width: 157px;
            height: 49px;
            background: rgba(0, 0, 0, 0.3);
            border-radius: 24.5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            position: relative;

            .count-down {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 52px;
                margin-left: 16px;

                .time-circle {
                    margin-top: -1.5px;
                    width: 38px;
                    height: 38px;
                    border-radius: 19px;
                    background-color: #EB445A;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: var(--system-white);
                    font-size: var(--caption2);
                    position: relative;

                    &.blink {
                        animation: time-circle 1s ease-in-out infinite;
                    }

                    @keyframes time-circle {
                        0% {
                            opacity: 1;
                            transform: scale(1);
                        }

                        50% {
                            opacity: 0.5;
                            transform: scale(1.1);
                        }

                        100% {
                            opacity: 1;
                            transform: scale(1);
                        }
                    }

                    .svg-container {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;

                        .path {
                            transition: stroke-dasharray 1s linear;
                        }
                    }
                }

                .count-title {
                    position: absolute;
                    bottom: -4px;
                    width: 52px;
                    height: 15px;

                    background: url(../../../assets/image/countdown-52x15@2x.png) no-repeat center/ 100%;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        background: url(../../../assets/image/countdown-52x15@3x.png) no-repeat center/ 100%;
                    }
                }
            }

            .suggest-token {
                display: flex;
                flex-direction: column;
                align-items: center;

                .token-section {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .token-icon {
                        width: 22px;
                        height: 22px;
                        background: url(../../../assets/image/token-100x100@2x.png) no-repeat center/ 100%;

                        @media screen and (-webkit-min-device-pixel-ratio: 3),
                        screen and (min--moz-device-pixel-ratio: 3) {
                            background: url(../../../assets/image/token-100x100@3x.png) no-repeat center/ 100%;
                        }
                    }

                    .token-label {
                        color: var(--system-white);
                        font-size: var(--caption2);
                        font-family: var(--font-weight-system-medium);
                    }
                }
            }

            .token-price {
                color: var(--system-white);
                font-size: var(--caption2);
                font-family: var(--font-weight-system-medium);
            }

            .arrow {
                margin-right: 10px;
                width: 16px;
                height: 16px;
                background: url(../../../assets/image/arrow-top-16x16@2x.png) no-repeat center/ 100%;

                @media screen and (-webkit-min-device-pixel-ratio: 3),
                screen and (min--moz-device-pixel-ratio: 3) {
                    background: url(../../../assets/image/arrow-top-16x16@3x.png) no-repeat center/ 100%;
                }
            }

        }

        .right {
            display: flex;
            flex-direction: row;
            background: rgba(0, 0, 0, 0.3);
            width: 49px;
            height: 49px;
            align-items: center;
            justify-content: center;
            border-radius: 24.5px;

            .gift-button {
                width: 49px;
                height: 49px;
                position: relative;
            }

        }
    }

    .list-container {
        position: absolute;
        left: 16px;
        right: 16px;
        bottom: calc(49px + 8px + env(safe-area-inset-bottom));
        height: 290px;
        padding-bottom: env(safe-area-inset-bottom);
        z-index: 2;
        display: flex;
        flex-direction: column-reverse;
        padding: 5px 0;
        overflow-y: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        .message-item-view {
            max-width: 258px;
            display: flex;
            margin-bottom: 4px;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;

            .text-main {
                padding: 8px;
                background: rgba(0, 0, 0, 0.3);
                border-radius: 17px;
                font-size: var(--subheadline);
                line-height: calc(var(--subheadline) + 2px);
                color: var(--system-white);
                position: relative;

                &.gift-text {
                    padding-right: 40px;
                }

                .nickname {
                    color: var(--system-yellow);

                    &.self {
                        color: var(--system-pink);
                    }
                }
            }

            .gift {
                top: calc((100% - 24px) / 2);
                right: 8px;
                position: absolute;
                display: block;
                width: 24px;
                height: 24px;
            }

            .translate-button {
                cursor: pointer;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-left: 8px;
                padding-top: 4px;
                padding-right: 8px;

                .icon {
                    width: 16px;
                    height: 16px;
                    background-color: var(--system-royal);
                    mask: url(../../../assets/image/translate-16x16@2x.png) no-repeat center/ 100%;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        mask: url(../../../assets/image/translate-16x16@3x.png) no-repeat center/ 100%;
                    }
                }

                .label {
                    margin-left: 4px;
                    color: var(--system-royal);
                    font-size: var(--subheadline);
                }
            }
        }
    }

    .keyboard-mask {
        position: absolute;
        z-index: 4;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: var(--dialog-mask);
        display: flex;
        align-items: flex-end;

        .input-wrapper {
            height: 49px;
            width: 100%;
            background-color: var(--secondary-grouped-background);
            display: flex;
            align-items: center;

            .input {
                height: 40px;
                width: 100%;
                padding: 0 16px;
                color: var(--text-primary);
                font-size: var(--footnote);
            }
        }
    }

    .gift-button-lottie-lover {
        position: absolute;
        z-index: 2;
        right: 29.5px;
        bottom: calc(49px + env(safe-area-inset-bottom));
        width: 25px;
        height: 130px;
        pointer-events: none;
    }

    .gift-svga-player {
        position: absolute;
        z-index: 2;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        pointer-events: none;
        overflow: hidden;
    }

    .gift-container {
        position: absolute;
        z-index: 3;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: flex;
        align-items: flex-end;

        .gift-mask {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            z-index: 3;
            background-color: var(--dialog-mask);
        }

        .gift-list-view {
            z-index: 4;
            width: 100%;
            height: calc(349px + env(safe-area-inset-bottom));
            padding-bottom: env(safe-area-inset-bottom);
            background: rgba(0, 0, 0, 0.5);
            backdrop-filter: blur(50px);
            display: flex;
            flex-direction: column;

            .list-header {
                width: 100%;
                height: 44px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                .title {
                    margin-left: 16px;
                    font-size: var(--subheadline);
                    color: var(--system-white);
                    font-weight: var(--font-weight-system-medium);
                }

                .token {
                    margin-right: 16px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;

                    .token-image {
                        width: 22px;
                        height: 22px;
                        margin-right: 3px;
                        background: url(../../../assets/image/token-100x100@2x.png) no-repeat center/ 22px 22px;

                        @media screen and (-webkit-min-device-pixel-ratio: 3),
                        screen and (min--moz-device-pixel-ratio: 3) {
                            background: url(../../../assets/image/token-100x100@3x.png) no-repeat center/ 22px 22px;
                        }
                    }

                    .token-label {
                        font-size: var(--footnote);
                        color: var(--system-white);
                        font-weight: var(--font-weight-system-medium);
                    }
                }
            }

            .list {
                // margin-top: 16px;
                width: 100%;
                height: calc(100% - 16px);
                display: flex;
                flex-wrap: wrap;
                overflow-y: auto;
            }

            .gift-item-view {
                width: 25%;
                height: 125px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .image {
                    width: 65px;
                    height: 65px;
                }

                .name {
                    font-size: var(--footnote);
                    color: var(--system-white);
                    font-weight: var(--font-weight-system-medium);
                }

                .price {
                    margin-top: 5px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;

                    .price-token {
                        width: 16px;
                        height: 16px;
                        margin-right: 3px;
                        background: url(../../../assets/image/token-100x100@2x.png) no-repeat center/ 16px 16px;

                        @media screen and (-webkit-min-device-pixel-ratio: 3),
                        screen and (min--moz-device-pixel-ratio: 3) {
                            background: url(../../../assets/image/token-100x100@3x.png) no-repeat center/ 16px 16px;
                        }
                    }

                    .price-label {
                        font-size: var(--footnote);
                        color: var(--system-white);
                        font-weight: var(--font-weight-system-medium);
                    }
                }
            }
        }
    }

    .gift-banner {
        position: absolute;
        z-index: 2;
        left: 16px;
        width: 201px;
        height: 34px;
        bottom: calc(349px + env(safe-area-inset-bottom));
        padding-bottom: env(safe-area-inset-bottom);
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(50px);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background: url(../../../assets/image/gift-banner-201x34@2x.png) no-repeat center/ 100%;

        @media screen and (-webkit-min-device-pixel-ratio: 3),
        screen and (min--moz-device-pixel-ratio: 3) {
            background: url(../../../assets/image/gift-banner-201x34@3x.png) no-repeat center/ 100%;
        }

        .avatar {
            margin-left: 5px;
            width: 24px;
            height: 24px;
            border-radius: 12px;
        }

        .nickname {
            width: calc(100% - 24px - 5px - 5px - 34px - 34px - 5px);
            flex-wrap: nowrap;
            margin-left: 5px;
            font-size: var(--footnote);
            color: var(--system-white);
            font-weight: var(--font-weight-system-medium);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .gift {
            width: 34px;
            height: 34px;
            margin-right: 34px;
        }
    }
}