[data-theme='light'] {
    --background: rgba(255, 255, 255, 1);
    --background-light: rgba(249, 249, 249, 1);
    --secondary-background: rgba(239, 239, 239, 1);
    --tertiary-background: rgba(255, 255, 255, 1);
    --grouped-background: rgb(239, 239, 239);
    --secondary-grouped-background: rgba(255, 255, 255, 1);
    --tertiary-grouped-background: rgba(239, 239, 239, 1);
    --text-primary: rgba(0, 0, 0, 1);
    --text-secondary: rgba(60, 60, 67, 0.6);
    --text-tertiary: rgba(60, 60, 67, 0.3);
    --text-quaternary: rgba(60, 60, 67, 0.18);
    --system-white: rgba(255, 255, 255, 1);
    --system-black: rgba(0, 0, 0, 1);
    --system-blue: rgba(0, 122, 255, 1);
    --system-royal: rgba(61, 116, 210, 1);
    --system-pink: rgba(255, 45, 85, 1);
    --system-red: rgba(255, 59, 48, 1);
    --system-green: rgba(52, 199, 89, 1);
    --system-yellow: rgba(255, 204, 0, 1);
    --system-orange: rgba(255, 149, 0, 1);
    --system-grey3: rgba(199, 199, 204, 1);
    --separator: rgba(219, 219, 219, 1);
    --dialog-mask: rgba(0, 0, 0, 0.4);
}

[data-theme='dark'] {
    --background: rgba(0, 0, 0, 1);
    --background-light: rgba(31, 31 31, 1);
    --secondary-background: rgba(28, 28, 28, 1);
    --tertiary-background: rgba(44, 44, 44, 1);
    --grouped-background: rgba(0, 0, 0, 1);
    --secondary-grouped-background: rgba(28, 28, 28, 1);
    --tertiary-grouped-background: rgba(44, 44, 44, 1);
    --text-primary: rgba(255, 255, 255, 1);
    --text-secondary: rgba(235, 235, 245, 0.6);
    --text-tertiary: rgba(235, 235, 245, 0.3);
    --text-quaternary: rgba(235, 235, 245, 0.18);
    --system-white: rgba(255, 255, 255, 1);
    --system-black: rgba(0, 0, 0, 1);
    --system-blue: rgba(10, 132, 255, 1);
    --system-royal: rgba(66, 124, 224, 1);
    --system-pink: rgba(255, 55, 95, 1);
    --system-red: rgba(255, 69, 58, 1);
    --system-green: rgba(48, 209, 88, 1);
    --system-yellow: rgba(255, 214, 0, 1);
    --system-orange: rgba(255, 159, 0, 1);
    --system-grey3: rgba(72, 72, 74, 1);
    --separator: rgba(37, 37, 37, 1);
    --dialog-mask: rgba(0, 0, 0, 0.6);
}