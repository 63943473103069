// Button
.touch-opacity {
    user-select: none;
    cursor: pointer;

    &:active {
        opacity: 0.5;
    }
}

.touch-highlight {
    user-select: none;
    position: relative;
    cursor: pointer;

    &:active {
        overflow: hidden;
        border-color: rgba(0, 0, 0, 0.47) !important;

        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.47) !important;
        }
    }
}

.touch-soft-highlight {
    user-select: none;
    position: relative;
    cursor: pointer;
    transition: background-color 0.2s;

    &:active {
        transition: background-color 0s;
        background-color: var(--tertiary-grouped-background) !important;
    }
}

.touch-without-feedback {
    user-select: none;
    cursor: pointer;
}

.disabled {
    pointer-events: none;
}

.disabled-opacity {
    pointer-events: none;
    opacity: 0.3;
}