.call-page {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    background-color: var(--system-black);
    margin: 0 auto;
    max-width: var(--max-screen-width);

    .lottie-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        // transform: rotate3d(0, 1, 0, 180deg);
    }

    .background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;

        .image {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
        }

        .mask {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, .5);
            backdrop-filter: blur(15px);
            z-index: 1;
        }
    }

    .circle-container {
        z-index: 1;
        width: 145px;
        height: 145px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        position: relative;

        .circle {
            position: absolute;
            top: 4px;
            left: 4px;
            width: 137px;
            height: 137px;
            z-index: 1;

            .path {
                transition: stroke-dasharray 1s linear;
            }
        }

        .avatar {
            z-index: 2;
            width: 125px;
            height: 125px;
            border-radius: 62.5px;
        }
    }

    .nickname {
        z-index: 1;
        color: var(--system-white);
        font-size: var(--title3);
        margin-top: 22.5px;
    }

    .price {
        z-index: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 10px;

        .icon {
            width: 16px;
            height: 166px;
            margin-right: 3px;
            background: url(../../../assets/image/token-100x100@2x.png) no-repeat center/ 100%;

            @media screen and (-webkit-min-device-pixel-ratio: 3),
            screen and (min--moz-device-pixel-ratio: 3) {
                background: url(../../../assets/image/token-100x100@3x.png) no-repeat center/ 100%;
            }
        }

        .label {
            color: var(--system-orange);
            font-size: var(--footnote);
            font-weight: var(--font-weight-system-medium);
        }

    }

    .content-main {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .animation-move-step-1 {
        animation: move 1.5s ease-in-out;

        @keyframes move {
            from {
                transform: translateY(200px);
                opacity: 0;
            }

            to {
                transform: translateY(0px);
                opacity: 1;
            }
        }
    }

    .animation-rotate {
        animation: rotate 4s linear infinite;

        @keyframes rotate {
            from {
                transform: rotate(0deg);
            }

            to {
                transform: rotate(360deg);
            }
        }
    }

    .countdown {
        position: absolute;
        z-index: 1;
        top: 44px;
        right: 16px;
        width: 56px;
        height: 56px;
        background: #AF52DE;
        border-radius: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--system-white);
        font-size: var(--headline);
    }

    .desc {
        z-index: 1;
        margin-top: 150px;
        color: rgba(255, 255, 255, 0.6);
        font-size: var(--footnote);
        text-align: center;
        width: 288px;
        height: 44px;
        overflow: hidden;
    }

    .tips {
        z-index: 1;
        margin-top: 80px;
        text-align: center;
        width: 288px;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        .content-view {
            z-index: 2;
            background-color: var(--secondary-grouped-background);
            width: 270px;
            border-radius: 14px;
            overflow: hidden;

            .title {
                margin-top: 19px;
                text-align: center;
                margin-left: 16px;
                margin-right: 16px;
                word-wrap: break-word;
                font-weight: var(--font-weight-system-semibold);
                font-size: var(--headline);
            }

            .message {
                margin-top: 5px;
                margin-bottom: 19px;
                text-align: center;
                margin-left: 16px;
                margin-right: 16px;
                word-wrap: break-word;
                font-weight: var(--font-weight-system-regular);
                font-size: var(--footnote);
            }

            .action-wrapper {
                border-top: 1px solid var(--separator);
                display: flex;
                flex-direction: row;
                height: 44px;

                .action-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex: 1;
                    font-weight: var(--font-weight-system-regular);
                    font-size: var(--headline);
                    color: var(--system-blue);

                    &:not(:last-child) {
                        font-weight: var(--font-weight-system-semibold);
                    }

                    &:not(:last-child) {
                        border-right: 1px solid var(--separator);
                    }

                    .circle {
                        position: absolute;
                        width: 54px;
                        height: 31px;
                        background: url(../../../assets/image/tips-circle-54x31@2x.png) no-repeat center/ 100%;

                        @media screen and (-webkit-min-device-pixel-ratio: 3),
                        screen and (min--moz-device-pixel-ratio: 3) {
                            background: url(../../../assets/image/tips-circle-54x31@3x.png) no-repeat center/ 100%;
                        }
                    }
                }
            }
        }

        .poiner {
            margin-top: 5px;
            width: 50px;
            height: 50px;
            align-self: flex-end;
            margin-right: calc(25% - 12.5px);
            background: url(../../../assets/image/pointer-50x50@2x.png) no-repeat center/100%;

            @media screen and (-webkit-min-device-pixel-ratio: 3),
            screen and (min--moz-device-pixel-ratio: 3) {
                background: url(../../../assets/image/pointer-50x50@3x.png) no-repeat center/100%;
            }
        }

        .tips-desc {
            margin-top: 10px;
            z-index: 1;
            color: rgba(255, 255, 255, 0.6);
            font-size: var(--footnote);
            text-align: center;
            width: 288px;
            height: 44px;
            overflow: hidden;
        }
    }

    .buttons {
        z-index: 1;
        margin-top: 44px;
        display: flex;
        width: calc(100% - 64px);
        align-items: center;
        justify-content: center;
        gap: 85px;

        .answer-button {
            width: 70px;
            height: 70px;
            border-radius: 35px;
            background-color: var(--system-green);
            position: relative;


            &::after {
                width: 33px;
                height: 33px;
                top: calc((100% - 33px) / 2);
                left: calc((100% - 33px) / 2);
                content: '';
                position: absolute;
                background: url(../../../assets/image/answer-29x29@2x.png) no-repeat center/ 100%;

                @media screen and (-webkit-min-device-pixel-ratio: 3),
                screen and (min--moz-device-pixel-ratio: 3) {
                    background: url(../../../assets/image/answer-29x29@3x.png) no-repeat center/ 100%;
                }
            }
        }

        .hangup-button {
            width: 70px;
            height: 70px;
            border-radius: 35px;
            background-color: var(--system-red);
            position: relative;

            &::after {
                width: 33px;
                height: 33px;
                top: calc((100% - 33px) / 2);
                left: calc((100% - 33px) / 2);
                content: '';
                position: absolute;
                background: url(../../../assets/image/hangup-33x33@2x.png) no-repeat center/ 33px 33px;

                @media screen and (-webkit-min-device-pixel-ratio: 3),
                screen and (min--moz-device-pixel-ratio: 3) {
                    background: url(../../../assets/image/hangup-33x33@3x.png) no-repeat center/ 33px 33px;
                }
            }

        }
    }

    .call-bottom-lottie {
        position: absolute;
        z-index: 2;
        bottom: env(safe-area-inset-bottom);
        left: 0;
        right: 0;
    }
}