.toast-component {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    text-align: center;

    .content {
        padding: 12px;
        background-color: rgba(38, 38, 38, 1);
        border-radius: 8px;
        color: var(--system-white);
        font-size: var(--footnote);
        transition: opacity .2s ease-in-out;
        max-width: calc(100% - 36px);

        &.hide {
            opacity: 0;
        }
    }
}