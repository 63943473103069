.ranking-page {
    display: flex;
    flex-direction: column;
    min-height: 100%;

    .header-component .header-content::before {
        height: 0;
    }

    .segmented-control {
        position: fixed;
        z-index: 1000;
        height: 44px;
        width: 100%;
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid var(--separator);
        top: calc(env(safe-area-inset-top) + 44px);
        background-color: var(--background);
        max-width: var(--max-screen-width);
        margin: 0 auto;

        .item {
            flex: 1;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .label {
                font-size: var(--headline);
                color: var(--text-primary);
                font-weight: var(--font-weight-system-medium);
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
                border-bottom: 1.5px solid rgba(0, 0, 0, 0);
            }

            &.active .label {
                color: var(--system-pink);
                border-bottom: 1.5px solid var(--system-pink);
            }
        }
    }

    .date-control {
        position: fixed;
        z-index: 1000;
        height: 28px;
        width: calc(100% - 32px);
        display: flex;
        flex-direction: row;
        top: calc(env(safe-area-inset-top) + 88px);
        background-color: var(--background);
        max-width: calc(var(--max-screen-width) - 32px);
        background-color: var(--secondary-background);
        margin: 10px 16px;
        border-radius: 6px;

        .item {
            margin: 2px;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;

            .label {
                font-size: var(--subheadline);
                color: var(--text-primary);
                font-weight: var(--font-weight-system-medium);
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
                border-bottom: 1.5px solid rgba(0, 0, 0, 0);
            }

            &.active {
                background-color: var(--tertiary-background);
            }
        }
    }

    &.lover .list-view {
        margin-top: 0px;
    }

    .list-view {
        margin-top: 88px;
        flex: 1;
        display: flex;
        flex-direction: column;

        .list {
            flex: 1;
        }

        .ranking-list-cell-item {
            height: 74px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .touch {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 1;
            }

            .topic-number {
                z-index: 2;
                position: absolute;
                left: 16px;
                width: 28px;
                height: 28px;
                display: flex;
                align-items: center;
                justify-content: center;
                pointer-events: none;
                font-size: var(--subheadline);
                color: var(--system-primary);
                font-weight: var(--font-weight-system-medium);

                &.top1 {
                    background: url(../../../assets/image/ranking-top1-28x28@2x.png) no-repeat center/ 100%;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        background: url(../../../assets/image/ranking-top1-28x28@3x.png) no-repeat center/ 100%;
                    }
                }


                &.top2 {
                    background: url(../../../assets/image/ranking-top2-28x28@2x.png) no-repeat center/ 100%;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        background: url(../../../assets/image/ranking-top2-28x28@3x.png) no-repeat center/ 100%;
                    }
                }


                &.top3 {
                    background: url(../../../assets/image/ranking-top3-28x28@2x.png) no-repeat center/ 100%;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        background: url(../../../assets/image/ranking-top3-28x28@3x.png) no-repeat center/ 100%;
                    }
                }
            }

            .avatar {
                z-index: 2;
                width: 50px;
                height: 50px;
                border-radius: 25px;
                margin-left: 54px;
                pointer-events: none;
            }

            .avatar-1 {
                z-index: 3;
                width: 50px;
                height: 50px;
                border-radius: 25px;
                margin-left: 54px;
                pointer-events: none;
            }

            .avatar-2 {
                z-index: 2;
                margin-left: -25px;
                width: 50px;
                height: 50px;
                border-radius: 25px;
                pointer-events: none;
            }

            .nickname-label {
                z-index: 2;
                flex: 1;
                margin-left: 8px;
                font-size: var(--subheadline);
                color: var(--system-primary);
                pointer-events: none;
            }

            .hot-wrapper {
                z-index: 3;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                margin-right: 16px;
                pointer-events: none;

                .hot-icon {
                    margin-right: 5px;

                    &.anchor {
                        width: 10px;
                        height: 14px;
                        background: url(../../../assets/image/ranking-anchor-10x14@2x.png) no-repeat center/ 100%;

                        @media screen and (-webkit-min-device-pixel-ratio: 3),
                        screen and (min--moz-device-pixel-ratio: 3) {
                            background: url(../../../assets/image/ranking-anchor-10x14@3x.png) no-repeat center/ 100%;
                        }
                    }

                    &.gold {
                        width: 12px;
                        height: 14px;
                        background: url(../../../assets/image/ranking-gold-12x14@2x.png) no-repeat center/ 100%;

                        @media screen and (-webkit-min-device-pixel-ratio: 3),
                        screen and (min--moz-device-pixel-ratio: 3) {
                            background: url(../../../assets/image/ranking-gold-12x14@3x.png) no-repeat center/ 100%;
                        }
                    }

                    &.gift {
                        width: 12px;
                        height: 14px;
                        background: url(../../../assets/image/ranking-gift-12x14@2x.png) no-repeat center/ 100%;

                        @media screen and (-webkit-min-device-pixel-ratio: 3),
                        screen and (min--moz-device-pixel-ratio: 3) {
                            background: url(../../../assets/image/ranking-gift-12x14@3x.png) no-repeat center/ 100%;
                        }
                    }

                    &.lover {
                        width: 14px;
                        height: 12px;
                        background: url(../../../assets/image/ranking-lover-14x12@2x.png) no-repeat center/ 100%;

                        @media screen and (-webkit-min-device-pixel-ratio: 3),
                        screen and (min--moz-device-pixel-ratio: 3) {
                            background: url(../../../assets/image/ranking-lover-14x12@3x.png) no-repeat center/ 100%;
                        }
                    }
                }

                .hot-label {
                    font-size: var(--footnote);
                    color: var(--system-primary);
                }
            }
        }
    }

}