.notification-component {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 44px;
    position: fixed;
    top: calc(20px + env(safe-area-inset-top));
    height: 70px;
    left: 16px;
    right: 16px;
    z-index: 1001;
    background-color: var(--tertiary-grouped-background);
    margin: 0 auto;
    max-width: calc(var(--max-screen-width) - 32px);
    transition: all .2s ease-in-out;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    backdrop-filter: blur(10px);
    cursor: pointer;

    &.hide {
        pointer-events: none;
        opacity: 0;
        transform: translateY(-61px);
    }

    .avatar {
        width: 45px;
        height: 45px;
        border-radius: 22.5px;
        margin-left: 16px;
        margin-right: 16px;
        flex-shrink: 0;
        overflow: hidden;

        .img {
            width: 100%;
            height: 100%;
            background: url(../../assets/image/default-avatar-1024x1024.png) no-repeat center/ cover;
        }
    }

    .content {
        width: calc(100% - 77px - 36px);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .nickname-label {
            font-size: var(--subheadline);
            font-weight: var(--font-weight-system-medium);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .message-label {
            margin-top: 5px;
            font-size: var(--footnote);
            font-weight: var(--font-weight-system-regular);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }


        .arrow {
            width: 10px;
            height: 16px;
            position: absolute;
            right: 16px;
            top: calc((100% - 16px) / 2);
            background-color: var(--text-primary);
            mask: url(../../assets/image/arrow-right-10x16@2x.png) no-repeat center/ cover;

            @media screen and (-webkit-min-device-pixel-ratio: 3),
            screen and (min--moz-device-pixel-ratio: 3) {
                mask: url(../../assets/image/arrow-right-10x16@3x.png) no-repeat center/ cover;
            }
        }
    }
}