.busy-modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1001;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s ease-in-out;

    &.hide {
        opacity: 0;
        pointer-events: none;

        .content-view {
            transform: translateY(20px)
        }
    }

    .mask {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: var(--dialog-mask);
        z-index: 1;
    }

    .content-view {
        z-index: 2;
        background-color: var(--background);
        width: calc(100% - 32px);
        max-width: calc(375px - 32px);
        border-radius: 6px;
        overflow: hidden;
        transition: all .2s ease-in-out;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .title {
            margin-top: 20px;
            text-align: center;
            font-size: var(--title2);
            font-weight: var(--font-weight-system-medium);
        }

        .sub-title {
            margin-top: 5px;
            margin-bottom: 20px;
            text-align: center;
            font-size: var(--footnote);
            color: var(--text-secondary)
        }

        .line {
            height: 1px;
            background-color: var(--separator);
            width: 100%;
        }

        .list {
            display: flex;
            flex-direction: row;
            width: 100%;
            flex-wrap: wrap;
            padding-top: 20px;
            padding-bottom: 5px;

            .list-item-view {
                width: calc((100% - 45px) / 2);
                height: 175px;
                margin-bottom: 10px;
                border: 1px solid var(--separator);
                border-radius: 6px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &:nth-child(odd) {
                    margin-left: 20px;
                }

                &:nth-child(even) {
                    margin-left: 10px;
                }

                .avatar {
                    width: 95px;
                    height: 95px;
                    border-radius: 45.5px;
                }

                .nickname {
                    font-weight: var(--font-weight-system-medium);
                    font-size: var(--subheadlind);
                    text-align: center;
                    margin-top: 5px;
                }
            }
        }
    }
}