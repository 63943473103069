.hot-page {
    display: flex;
    min-height: 100%;
    flex-direction: column;
    padding-bottom: calc(49px + env(safe-area-inset-bottom));

    .hot-header {
        height: calc(44px + env(safe-area-inset-top));
        z-index: 1000;

        .hot-header-content {
            position: fixed;
            z-index: 1000;
            padding-top: env(safe-area-inset-top);
            height: calc(44px + env(safe-area-inset-top));
            width: 100%;
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid var(--separator);
            top: 0px;
            background-color: var(--background);
            max-width: var(--max-screen-width);
            margin: 0 auto;
            align-items: center;

            ::-webkit-scrollbar {
                display: none;
            }

            .segmented-control {
                display: flex;
                flex: 1;
                flex-direction: row;
                overflow-x: scroll;
                height: 100%;

                .item {
                    margin: 0 8px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    white-space: nowrap;

                    &:first-child {
                        margin-left: 16px;
                    }

                    &:last-child {
                        margin-right: 16px;
                    }

                    .label {
                        color: var(--text-primary);
                        font-weight: var(--font-weight-system-semibold);
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        box-sizing: border-box;
                        border-bottom: 1.5px solid rgba(0, 0, 0, 0);
                    }

                    &.active .label {
                        font-size: var(--title2);
                        color: var(--system-pink);
                        border-bottom: 1.5px solid var(--system-pink);
                    }
                }

            }

            .header-token-view {
                z-index: 2;
                top: calc(env(safe-area-inset-top) + 10px);
                margin-right: 16px;
                border-radius: 12px;
                height: 24px;
                background-color: var(--tertiary-grouped-background);
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                .token-icon {
                    margin-left: 10px;
                    margin-right: 5px;
                    width: 22px;
                    height: 22px;
                    background: url(../../../assets/image/token-100x100@2x.png) no-repeat center/ cover;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        background: url(../../../assets/image/token-100x100@3x.png) no-repeat center/ cover;
                    }
                }

                .token-label {
                    margin-right: 5px;
                    color: var(--text-primary);
                    font-size: var(--subheadline);
                    font-weight: var(--font-weight-system-medium);
                }


                .add-icon {
                    width: 22px;
                    height: 22px;
                    background: url(../../../assets/image/add-round-22x22@2x.png) no-repeat center/ cover;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        background: url(../../../assets/image/add-round-22x22@3x.png) no-repeat center/ cover;
                    }
                }
            }

            .rank-button {
                z-index: 1000;
                margin-right: 16px;
                top: calc(env(safe-area-inset-top) + 8px);
                width: 28px;
                height: 28px;
                background: url(../../../assets/image/ranking-28x28@2x.png) no-repeat center/ 100%;

                @media screen and (-webkit-min-device-pixel-ratio: 3),
                screen and (min--moz-device-pixel-ratio: 3) {
                    background: url(../../../assets/image/ranking-28x28@3x.png) no-repeat center/ 100%;
                }
            }
        }
    }

    .hot-page-container {
        display: flex;
        flex-direction: column;
        flex: 1;
        min-height: 100%;

        .list {
            min-height: 100%;
            flex: 1;
            flex-direction: row;
            flex-flow: row wrap;
        }

        &.following {

            .hot-page-following-empty {
                margin: 0 auto;
                height: 82px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                .hot-page-following-empty-icon {
                    margin-right: 5px;
                    width: 22px;
                    height: 22px;
                    background: url(../../../assets/image/hot-following-empty-lover-22x22@2x.png) no-repeat center/ cover;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        background: url(../../../assets/image/hot-following-empty-lover-22x22@3x.png) no-repeat center/ cover;
                    }
                }

                .hot-page-following-empty-label {
                    font-weight: var(--font-weight-system-medium);
                    color: var(--text-secondary);
                    font-size: var(--headline);
                }
            }

            .hot-page-following-also-title {
                margin-left: 16px;
                padding-top: 16px;
                padding-bottom: 16px;
                color: var(--text-primary);
                font-size: var(--headline);
                font-weight: var(--font-weight-system-semibold);
            }
        }

        &.new {
            .anchor-cell {
                width: calc(33.33% - 1px);
                padding-bottom: 33.33%;

                &:nth-child(8n + 1) {
                    width: calc(50% - 1px);
                    padding-bottom: 50%;
                }

                &:nth-child(8n + 2) {
                    width: calc(50% - 1px);
                    padding-bottom: 50%;
                }
            }
        }

        &.top {

            .list {
                display: grid;
                grid-gap: 1px;
                grid-auto-flow: row dense;
                grid-template-columns: 1fr 1fr 1fr;
            }

            .anchor-cell {
                width: 100%;
                height: calc((min(var(--max-screen-width), 100vw) / 3) - 2px);

                &:nth-child(1) {
                    grid-area: 1 / 1 / span 2 / span 2;
                    height: calc((min(var(--max-screen-width), 100vw) / 1.5) - 2px);
                }

                &:nth-child(12) {
                    grid-area: 5 / 2 / span 2 / span 2;
                    height: calc((min(var(--max-screen-width), 100vw) / 1.5) - 2px);
                }

                &:nth-child(19) {
                    grid-area: 9 / 1 / span 2 / span 2;
                    height: calc((min(var(--max-screen-width), 100vw) / 1.5) - 2px);
                }

                &:nth-child(29) {
                    grid-area: 13 / 2 / span 2 / span 2;
                    height: calc((min(var(--max-screen-width), 100vw) / 1.5) - 2px);
                }
            }

            .list-footer-view {
                width: 300%;
            }
        }
    }
}