.first-recharge-modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1001;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s ease-in-out;

    &.hide {
        opacity: 0;
        pointer-events: none;

        .content-view {
            transform: translateY(20px)
        }
    }

    .mask {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: var(--dialog-mask);
        z-index: 1;
    }

    .content-view {
        z-index: 2;
        background-color: var(--background);
        width: calc(100% - 32px);
        max-width: calc(375px - 32px);
        border-radius: 6px;
        transition: all .2s ease-in-out;
        overflow: visible;

        .main-container {
            width: 100%;
            height: 0;
            padding-bottom: 118.37%;
            position: relative;
            background: url(../../assets/image/first-recharge-bg-343x406@2x.png) no-repeat center/ cover;
            background-color: #45157e;
            border-radius: 6px;

            @media screen and (-webkit-min-device-pixel-ratio: 3),
            screen and (min--moz-device-pixel-ratio: 3) {
                background: url(../../assets/image/first-recharge-bg-343x406@3x.png) no-repeat center/ cover;
            }

            .content {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                overflow: visible;

                .title {
                    width: 147px;
                    height: 50px;
                    background: linear-gradient(90deg, #775DFE 0%, #FA5CEF 100%);
                    border-radius: 25px;
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    margin-top: -25px;

                    .label {
                        color: var(--system-white);
                        text-align: center;
                        line-height: calc(var(--title2) + 4px);
                        font-size: 22px;
                        font-size: var(--title2);
                        font-weight: var(--font-weight-system-semibold);
                    }
                }

                .close-button {
                    width: 22px;
                    height: 22px;
                    position: absolute;
                    top: 17px;
                    right: 16px;
                    background: url(../../assets/image/close-opacity-22x22@2x.png) no-repeat center/ cover;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        background: url(../../assets/image/close-opacity-22x22@3x.png) no-repeat center/ cover;
                    }
                }

                .medal {
                    top: -32px;
                    left: -16px;
                    position: absolute;
                    width: 88px;
                    height: 88px;
                    background: url(../../assets/image/medal-88x88@2x.png) no-repeat center/ cover;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        background: url(../../assets/image/medal-88x88@3x.png) no-repeat center/ cover;
                    }
                }

                .gift-view {
                    margin-top: 24px;
                    width: 181px;
                    height: 191px;
                }

                .token-label {
                    margin-top: 5px;
                    color: var(--system-white);
                    font-size: 48px;
                    font-weight: var(--font-weight-system-semibold);
                    color: #FFFFFF;
                    line-height: 58px;
                    display: flex;
                    align-items: center;

                    .diamonds {
                        margin-left: 5px;
                        font-size: var(--headline);
                        font-weight: 600;
                        color: #FFFFFF;
                    }
                }

                .offer-container {
                    display: flex;
                    flex-direction: row;

                    .price-label {
                        height: 34px;
                        color: #ffffff;
                        font-size: var(--subheadline);
                        display: flex;
                        align-items: center;
                    }

                    .offer-view {
                        height: 34px;
                        border-radius: 6px;
                        display: flex;
                        align-items: center;
                        background-color: rgba(0, 0, 0, 0.3);

                        .offer-label {
                            margin: 0px 15px;
                            font-size: var(--headline);
                            font-weight: var(--font-weight-system-semibold);
                            font-family: SFProDisplay-Black, SFProDisplay;
                            background: linear-gradient(90deg, #53D6F9 0%, #F850FF 100%);
                            background-clip: text;
                            color: transparent;
                        }
                    }
                }

                .buy-button {
                    margin: 10px auto 20px;
                    width: 305px;
                    height: 44px;
                    background: #ffffff;
                    border-radius: 21px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: var(--system-black);
                    font-size: var(--headline);
                    font-weight: var(--font-weight-system-semibold);
                }
            }
        }
    }
}