.action-alert-components {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1001;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s ease-in-out;

    &.hide {
        opacity: 0;
        pointer-events: none;
    }

    .mask {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: var(--dialog-mask);
        z-index: 1;
    }

    .content-view {
        z-index: 2;
        background-color: var(--secondary-grouped-background);
        width: 270px;
        border-radius: 14px;
        overflow: hidden;

        .title {
            margin-top: 19px;
            text-align: center;
            margin-left: 16px;
            margin-right: 16px;
            word-wrap: break-word;
            font-weight: var(--font-weight-system-semibold);
            font-size: var(--headline);
        }

        .message {
            margin-top: 5px;
            margin-bottom: 19px;
            text-align: center;
            margin-left: 16px;
            margin-right: 16px;
            word-wrap: break-word;
            font-weight: var(--font-weight-system-regular);
            font-size: var(--footnote);
        }

        .action-wrapper {
            border-top: 1px solid var(--separator);
            display: flex;
            flex-direction: row;
            height: 44px;

            .action-button {
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 1;
                font-weight: var(--font-weight-system-regular);
                font-size: var(--headline);

                &:not(:last-child) {
                    border-right: 1px solid var(--separator);
                }
            }

        }
    }

}

.action-sheet-components {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1001;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    transition: all 0.2s ease-in-out;

    &.hide {
        opacity: 0;
        pointer-events: none;

        .content-view {
            transform: translateY(100%);
        }
    }

    .mask {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: var(--dialog-mask);
        z-index: 1;
    }

    .content-view {
        z-index: 2;
        width: calc(100% - 16px);
        max-width: calc(var(--max-screen-width) - 16px);
        overflow: hidden;
        margin-bottom: calc(33px + env(safe-area-inset-bottom));
        transition: all 0.2s ease-in-out;

        .action-wrapper {
            display: flex;
            flex-direction: column;

            .main {
                overflow: hidden;
                border-radius: 14px;
                background-color: var(--secondary-grouped-background);
            }

            .action-button {
                position: relative;
                background-color: var(--secondary-grouped-background);
                height: 56px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: var(--font-weight-system-regular);
                font-size: var(--headline);

                &:not(:last-child) {
                    border-bottom: 1px solid var(--separator);
                }
            }
        }

        .action-button-cancel {
            border-radius: 14px;
            margin-top: 8px;
        }
    }
}