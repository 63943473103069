.follow-page {
    display: flex;
    flex-direction: column;
    min-height: 100%;

    .header-component .header-content::before {
        height: 0;
    }

    .segmented-control {
        position: fixed;
        z-index: 999;
        height: 44px;
        width: 100%;
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid var(--separator);
        top: calc(env(safe-area-inset-top) + 44px);
        background-color: var(--background);
        max-width: var(--max-screen-width);
        margin: 0 auto;

        .item {
            flex: 1;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .label {
                font-size: var(--headline);
                color: var(--text-primary);
                font-weight: var(--font-weight-system-semibold);
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
                border-bottom: 1.5px solid rgba(0, 0, 0, 0);
            }

            &.active .label {
                color: var(--system-pink);
                border-bottom: 1.5px solid var(--system-pink);
            }
        }
    }

    .follow-list-cell-item {
        height: 65px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        .avatar {
            width: 50px;
            height: 50px;
            border-radius: 25px;
            left: 16px;
            margin-left: 16px;
            z-index: 2;
            pointer-events: none;
        }

        .nickname-wrapper {
            margin-left: 16px;
            display: flex;
            flex: 1;
            overflow: hidden;
            z-index: 2;
            pointer-events: none;

            .nickname {
                font-size: var(--subheadline);
                font-weight: var(--font-weight-system-semibold);
                color: var(--text-primary);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .touch {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        .remove-button {
            z-index: 2;
            border: 1px solid var(--separator);
            border-radius: 6px;
            width: 70px;
            height: 24px;
            font-size: var(--footnote);
            color: var(--text-primary);
            margin-left: 8px;
            margin-right: 16px;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
}

.segmented-page {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 44px;

    .list {
        flex: 1;
    }
}