.device-modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1001;
    display: flex;
    align-items: center;
    // justify-content: center;
    flex-direction: column;
    transition: all .2s ease-in-out;

    &.hide {
        opacity: 0;
        pointer-events: none;

        .content-view {
            transform: translateY(20px)
        }
    }

    .mask {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: var(--dialog-mask);
        z-index: 1;
    }

    .content-view {
        margin-top: calc(65px + env(safe-area-inset-bottom));
        z-index: 2;
        background-color: var(--secondary-grouped-background);
        border-radius: 6px;
        overflow: hidden;
        transition: all .2s ease-in-out;
        width: calc(100% - 32px);
        max-width: calc(var(--max-screen-width) - 32px);
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        justify-content: center;

        .image-logo {
            margin: 16px;
            flex-shrink: 0;
            width: 67px;
            height: 67px;
            background: url(../../assets/image/device-alert-logo-67x67@2x.png) no-repeat center/ cover;

            @media screen and (-webkit-min-device-pixel-ratio: 3),
            screen and (min--moz-device-pixel-ratio: 3) {
                background: url(../../assets/image/device-alert-logo-67x67@3x.png) no-repeat center/ cover;
            }
        }

        .head {
            display: flex;
            flex-direction: row;

        }

        .title {
            // margin-top: 20px;
            font-size: var(--headline);
            font-weight: var(--font-weight-system-semibold);
            text-align: center;
            margin-bottom: 16px;
        }

        .desc {
            margin-top: 20px;
            max-width: calc(100% - 32px);
            font-size: var(--subheadline);
            text-align: left;
            position: relative;

            .headline-1 {
                padding-left: 16px;

                // &::before {
                //     content: "1.";
                //     position: absolute;
                //     left: 0px;
                // }
            }

            .headline-2 {
                padding-left: 16px;

                // &::before {
                //     content: "2.";
                //     position: absolute;
                //     left: 0px;
                // }
            }
        }

        .next-button {
            margin-top: 22px;
            background-color: var(--system-pink);
            height: 44px;
            border-radius: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: calc(100% - 32px);
            color: var(--system-white);
            margin-bottom: 20px;
        }

        .close-button {
            position: absolute;
            z-index: 2;
            right: 16px;
            top: 16px;
            width: 22px;
            height: 22px;
            background-color: var(--text-secondary);
            mask: url(../../assets/image/close-22x22@2x.png) no-repeat center/ 100%;

            @media screen and (-webkit-min-device-pixel-ratio: 3),
            screen and (min--moz-device-pixel-ratio: 3) {
                mask: url(../../assets/image/close-22x22@3x.png) no-repeat center/ 100%;
            }
        }
    }
}