.tab-component {
    background-color: var(--background);
    border: 0;
    height: calc(49px + env(safe-area-inset-bottom));
    padding-bottom: env(safe-area-inset-bottom);
    left: 0;
    position: fixed;
    right: 0;
    z-index: 1000;
    bottom: 0;
    display: flex;
    margin: 0 auto;
    max-width: var(--max-screen-width);

    .tab {
        flex: 1 1 auto;
        min-height: 0;
        min-width: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: var(--text-secondary);
        position: relative;

        .icon {
            width: 22px;
            height: 22px;
            background-color: var(--text-secondary);

            &.explore {
                mask: url(../../assets/image/tab-explore-25x25@2x.png) no-repeat center/ 22px 22px;

                @media screen and (-webkit-min-device-pixel-ratio: 3),
                screen and (min--moz-device-pixel-ratio: 3) {
                    mask: url(../../assets/image/tab-explore-25x25@3x.png) no-repeat center/ 22px 22px;
                }
            }

            &.inbox {
                mask: url(../../assets/image/tab-inbox-25x25@2x.png) no-repeat center/ 22px 22px;

                @media screen and (-webkit-min-device-pixel-ratio: 3),
                screen and (min--moz-device-pixel-ratio: 3) {
                    mask: url(../../assets/image/tab-inbox-25x25@3x.png) no-repeat center/ 22px 22px;
                }
            }

            &.recharge {
                mask: url(../../assets/image/tab-shop-25x25@2x.png) no-repeat center/ 22px 22px;

                @media screen and (-webkit-min-device-pixel-ratio: 3),
                screen and (min--moz-device-pixel-ratio: 3) {
                    mask: url(../../assets/image/tab-shop-25x25@3x.png) no-repeat center/ 22px 22px;
                }
            }

            &.profile {
                mask: url(../../assets/image/tab-profile-25x25@2x.png) no-repeat center/ 22px 22px;

                @media screen and (-webkit-min-device-pixel-ratio: 3),
                screen and (min--moz-device-pixel-ratio: 3) {
                    mask: url(../../assets/image/tab-profile-25x25@3x.png) no-repeat center/ 22px 22px;
                }
            }

            &.hot {
                mask: url(../../assets/image/tab-hot-25x25@2x.png) no-repeat center/ 22px 22px;

                @media screen and (-webkit-min-device-pixel-ratio: 3),
                screen and (min--moz-device-pixel-ratio: 3) {
                    mask: url(../../assets/image/tab-hot-25x25@3x.png) no-repeat center/ 22px 22px;
                }
            }
        }

        &.active {
            color: var(--text-primary);

            .icon {
                background-color: var(--text-primary);

                &.explore {
                    mask: url(../../assets/image/tab-explore-selected-25x25@2x.png) no-repeat center/ 22px 22px;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        mask: url(../../assets/image/tab-explore-selected-25x25@3x.png) no-repeat center/ 22px 22px;
                    }
                }

                &.inbox {
                    mask: url(../../assets/image/tab-inbox-selected-25x25@2x.png) no-repeat center/ 22px 22px;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        mask: url(../../assets/image/tab-inbox-selected-25x25@3x.png) no-repeat center/ 22px 22px;
                    }
                }

                &.recharge {
                    mask: url(../../assets/image/tab-shop-selected-25x25@2x.png) no-repeat center/ 22px 22px;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        mask: url(../../assets/image/tab-shop-selected-25x25@3x.png) no-repeat center/ 22px 22px;
                    }
                }

                &.profile {
                    mask: url(../../assets/image/tab-profile-selected-25x25@2x.png) no-repeat center/ 22px 22px;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        mask: url(../../assets/image/tab-profile-selected-25x25@3x.png) no-repeat center/ 22px 22px;
                    }
                }

                &.hot {
                    mask: url(../../assets/image/tab-hot-selected-25x25@2x.png) no-repeat center/ 22px 22px;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        mask: url(../../assets/image/tab-hot-selected-25x25@3x.png) no-repeat center/ 22px 22px;
                    }
                }
            }
        }

        .text {
            margin-top: 2px;
            font-size: var(--caption2);
        }

        .badge {
            left: calc((100% - 18px) / 2);
            top: 2px;
            margin-left: 18px;
            width: 18px;
            height: 18px;
            position: absolute;
            background-color: var(--system-red);
            font-size: var(--caption2);
            color: var(--system-white);
            border-radius: 9px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .badge-cirlce {
            left: calc((100% - 4px) / 2);
            top: 2px;
            margin-left: 8px;
            width: 8px;
            height: 8px;
            border-radius: 4px;
            position: absolute;
            background-color: var(--system-red);
            font-size: var(--caption2);
            color: var(--system-white);
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &::before {
        background-color: var(--separator);
        content: '';
        height: 1px;
        left: 0;
        position: absolute;
        right: 0;
        top: -1px;
    }
}