.history-list-cell-item {
    height: 65px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .avatar {
        width: 50px;
        height: 50px;
        border-radius: 25px;
        left: 16px;
        margin-left: 16px;
        z-index: 2;
        pointer-events: none;
    }

    .main-wrapper {
        margin-left: 16px;
        display: flex;
        flex: 1;
        overflow: hidden;
        z-index: 2;
        pointer-events: none;
        flex-direction: column;
        padding-right: 62px;
        justify-content: center;

        .nickname {
            font-size: var(--subheadline);
            font-weight: var(--font-weight-system-semibold);
            color: var(--text-primary);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .time {
            margin-top: 5px;
            display: flex;
            flex-direction: row;

            .call-icon {
                width: 16px;
                height: 16px;
                margin-right: 5px;

                &.hangup {
                    background-color: var(--system-red);
                    mask: url(../../../../assets/image/call-hangup-16x16@2x.png) no-repeat center/ cover;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        mask: url(../../../../assets/image/call-hangup-16x16@3x.png) no-repeat center/ cover;
                    }
                }

                &.dial {
                    background-color: var(--system-green);
                    mask: url(../../../../assets/image/call-dial-16x16@2x.png) no-repeat center/ cover;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        mask: url(../../../../assets/image/call-dial-16x16@3x.png) no-repeat center/ cover;
                    }
                }
            }

            .view-icon {
                width: 16px;
                height: 16px;
                margin-right: 5px;
                background-color: var(--text-secondary);
                mask: url(../../../../assets/image/record-eye-16x16@2x.png) no-repeat center/ cover;

                @media screen and (-webkit-min-device-pixel-ratio: 3),
                screen and (min--moz-device-pixel-ratio: 3) {
                    mask: url(../../../../assets/image/record-eye-16x16@3x.png) no-repeat center/ cover;
                }
            }

            .time-label {
                font-size: var(--footnote);
                font-weight: var(--font-weight-system-regular);
                color: var(--text-secondary);
            }
        }
    }

    .call-button {
        z-index: 2;
        width: 22px;
        height: 22px;
        position: absolute;
        background-color: var(--text-primary);
        right: 32px;
        mask: url(../../../../assets/image/call-video-22x22@2x.png) no-repeat center/ cover;

        @media screen and (-webkit-min-device-pixel-ratio: 3),
        screen and (min--moz-device-pixel-ratio: 3) {
            mask: url(../../../../assets/image/call-video-22x22@3x.png) no-repeat center/ cover;
        }
    }

    .touch {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
}