.expenses-page {
    display: flex;
    flex-direction: column;
    min-height: 100%;

    .list {
        flex: 1;
    }

    .expenses-list-cell-item {
        height: 65px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        .avatar {
            width: 40px;
            height: 40px;
            border-radius: 20px;
            left: 16px;
            margin-left: 16px;
            z-index: 2;
            pointer-events: none;

            &.message .img {
                background: url(../../../assets/image/expenses-message-40x40@2x.png) no-repeat center/ cover;

                @media screen and (-webkit-min-device-pixel-ratio: 3),
                screen and (min--moz-device-pixel-ratio: 3) {
                    background: url(../../../assets/image/expenses-message-40x40@3x.png) no-repeat center/ cover;
                }
            }

            &.recharge .img {
                background: url(../../../assets/image/expenses-recharge-40x40@2x.png) no-repeat center/ cover;

                @media screen and (-webkit-min-device-pixel-ratio: 3),
                screen and (min--moz-device-pixel-ratio: 3) {
                    background: url(../../../assets/image/expenses-recharge-40x40@3x.png) no-repeat center/ cover;
                }
            }

            &.other .img {
                background: url(../../../assets/image/expenses-other-40x40@2x.png) no-repeat center/ cover;

                @media screen and (-webkit-min-device-pixel-ratio: 3),
                screen and (min--moz-device-pixel-ratio: 3) {
                    background: url(../../../assets/image/expenses-other-40x40@3x.png) no-repeat center/ cover;
                }
            }
        }

        .content-wrapper {
            margin-left: 10px;
            flex: 1;

            .title-label {
                color: var(--text-primary);
                font-size: var(--subheadline);
                font-weight: var(--font-weight-system-medium);
            }

            .time-label {
                margin-top: 5px;
                display: flex;
                flex-direction: row;
                align-items: center;

                .label-icon {
                    width: 16px;
                    height: 16px;
                    margin-right: 5px;

                    &.call {
                        background: url(../../../assets/image/call-dial-16x16@2x.png) no-repeat center/ cover;

                        @media screen and (-webkit-min-device-pixel-ratio: 3),
                        screen and (min--moz-device-pixel-ratio: 3) {
                            background: url(../../../assets/image/call-dial-16x16@3x.png) no-repeat center/ cover;
                        }
                    }

                    &.message {
                        background: url(../../../assets/image/message-16x16@2x.png) no-repeat center/ cover;

                        @media screen and (-webkit-min-device-pixel-ratio: 3),
                        screen and (min--moz-device-pixel-ratio: 3) {
                            background: url(../../../assets/image/message-16x16@3x.png) no-repeat center/ cover;
                        }
                    }
                }

                .label-text {
                    color: var(--text-secondary);
                    font-size: var(--caption1);
                }
            }
        }

        .token-wrapper {
            margin-right: 16px;
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 100%;

            .token-label {
                color: var(--system-green);
                font-size: var(--title3);
                font-weight: var(--font-weight-system-semibold);
            }

            .token-img {
                align-self: center;
                width: 22px;
                height: 22px;
                margin-right: 5px;
                background: url(../../../assets/image/token-100x100@2x.png) no-repeat center/ cover;

                @media screen and (-webkit-min-device-pixel-ratio: 3),
                screen and (min--moz-device-pixel-ratio: 3) {
                    background: url(../../../assets/image/token-100x100@3x.png) no-repeat center/ cover;
                }
            }
        }
    }

}