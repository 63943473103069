@use './components/global/index.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
  -webkit-tap-highlight-color: transparent;
}

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
  color: var(--text-primary);
  font-size: var(--body);
  display: flex;
  height: 100%;
  overflow-y: overlay;
  line-height: 1.58;
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
  -webkit-touch-callout: none;
}

input {
  background: none;
  outline: none;
  border: 0px;
  -webkit-appearance: none;
  box-shadow: none;

  &::input-placeholder {
    color: var(--text-secondary);
  }
}

textarea {
  background: none;
  outline: none;
  border: 0px;
  -webkit-appearance: none;
  box-shadow: none;

  &::-webkit-input-placeholder {
    color: var(--text-secondary);
  }

  &:-moz-placeholder {
    color: var(--text-secondary);
  }

  &::-moz-placeholder {
    color: var(--text-secondary);
  }

  &::-ms-input-placeholder {
    color: var(--text-secondary);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  flex: 1;
  width: 100%;
  max-width: var(--max-screen-width);
  margin: 0 auto;
  height: 100%;
  position: relative;

  &::after {
    content: '';
    position: fixed;
    top: -1px;
    margin: 0 auto;
    margin-left: -1px;
    height: calc(100% + 2px);
    width: calc(var(--max-screen-width) + 2px);
    z-index: -1;
    border: 1px solid var(--separator);
  }
}