.users-item-view {
    height: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    .avatar {
        width: 45px;
        height: 45px;
        border-radius: 22.5px;
        margin-left: 16px;
    }

    .badge {
        width: 18px;
        height: 18px;
        position: absolute;
        left: 43px;
        background-color: var(--system-red);
        font-size: var(--caption2);
        color: var(--system-white);
        border-radius: 9px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 9.5px;
    }

    .text-wrapper {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin-left: 16px;
        justify-content: center;
        overflow: hidden;

        .nickname-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .nickname-label {
                font-size: var(--subheadline);
                font-weight: var(--font-weight-system-medium);
                color: var(--text-primary);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .time-label {
                margin-left: 5px;
                font-size: var(--caption2);
                font-weight: var(--font-weight-system-regular);
                color: var(--text-secondary);
                margin-right: 16px;
            }
        }

        .message-label {
            margin-top: 5px;
            font-size: var(--footnote);
            font-weight: var(--font-weight-system-regular);
            color: var(--text-secondary);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 16px;

            &.call {
                color: var(--system-red);
            }
        }
    }
}