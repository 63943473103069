.settings-page {

    .section-label {
        height: 44px;
        margin-left: 16px;
        font-size: var(--headline);
        color: var(--text-primary);
        font-weight: var(--font-weight-system-semibold);
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .cell-item {
        height: 49px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .text-label {
            font-size: var(--subheadline);
            color: var(--text-primary);
            margin-left: 16px;
        }

        .detail-label {
            font-size: var(--subheadline);
            color: var(--text-secondary);
            margin-right: 16px;
        }

        .arrow {
            width: 10px;
            height: 16px;
            position: absolute;
            right: 16px;
            top: calc((100% - 16px) / 2);
            background-color: var(--text-secondary);
            mask: url(../../../assets/image/arrow-right-10x16@2x.png) no-repeat center/ 100% 100%;

            @media screen and (-webkit-min-device-pixel-ratio: 3),
            screen and (min--moz-device-pixel-ratio: 3) {
                mask: url(../../../assets/image/arrow-right-10x16@3x.png) no-repeat center/ 100% 100%;
            }
        }
    }

}