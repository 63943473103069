.spinner-component {
    display: flex;
    flex-direction: column;
    align-items: center;

    .loading {
        display: block;
        animation: loadingCircle 1s infinite linear;
        line-height: 0;

        svg {
            display: block;
        }

        @-webkit-keyframes loadingCircle {
            to {
                transform: rotate(360deg)
            }
        }

        @keyframes loadingCircle {
            to {
                transform: rotate(360deg)
            }
        }
    }
}