.explore-page {
    display: flex;
    min-height: 100%;
    flex-direction: column;
    padding-bottom: calc(49px + env(safe-area-inset-bottom));

    .explore-header {
        height: calc(44px + env(safe-area-inset-top));
        z-index: 1000;

        .explore-header-content {
            position: fixed;
            // border-bottom: 1px solid var(--separator);
            padding-top: env(safe-area-inset-top);
            height: calc(44px + env(safe-area-inset-top));
            width: 100%;
            max-width: var(--max-screen-width);
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            z-index: 1000;
            top: 0;
            background-color: var(--background);

            .explore-title {
                margin-left: 16px;
                font-weight: var(--font-weight-system-semibold);
                font-size: var(--title2);
                color: var(--system-pink);
            }

            .header-token-view {
                z-index: 2;
                position: absolute;
                top: calc(env(safe-area-inset-top) + 10px);
                right: 60px;
                border-radius: 12px;
                height: 24px;
                background-color: var(--tertiary-grouped-background);
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                .token-icon {
                    margin-left: 10px;
                    margin-right: 5px;
                    width: 22px;
                    height: 22px;
                    background: url(../../../assets/image/token-100x100@2x.png) no-repeat center/ cover;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        background: url(../../../assets/image/token-100x100@3x.png) no-repeat center/ cover;
                    }
                }

                .token-label {
                    margin-right: 5px;
                    color: var(--text-primary);
                    font-size: var(--subheadline);
                    font-weight: var(--font-weight-system-medium);
                }


                .add-icon {
                    width: 22px;
                    height: 22px;
                    background: url(../../../assets/image/add-round-22x22@2x.png) no-repeat center/ cover;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        background: url(../../../assets/image/add-round-22x22@3x.png) no-repeat center/ cover;
                    }
                }
            }

            .rank-button {
                z-index: 1000;
                position: absolute;
                right: 16px;
                top: calc(env(safe-area-inset-top) + 8px);
                width: 28px;
                height: 28px;
                background: url(../../../assets/image/ranking-28x28@2x.png) no-repeat center/ 100%;

                @media screen and (-webkit-min-device-pixel-ratio: 3),
                screen and (min--moz-device-pixel-ratio: 3) {
                    background: url(../../../assets/image/ranking-28x28@3x.png) no-repeat center/ 100%;
                }
            }
        }
    }

    .segmented-control {
        height: 44px;
        width: 100%;
        position: fixed;
        display: flex;
        flex-direction: row;
        top: 0px;
        background-color: var(--background);
        margin: 0 auto;

        .item {
            margin: 0 8px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            &:first-child {
                margin-left: 16px;
            }

            &:last-child {
                margin-right: 16px;
            }

            .label {
                color: var(--text-primary);
                font-weight: var(--font-weight-system-semibold);
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
                border-bottom: 1.5px solid rgba(0, 0, 0, 0);
            }

            &.active .label {
                font-size: var(--title2);
                color: var(--system-pink);
                border-bottom: 1.5px solid var(--system-pink);
            }
        }
    }

    .page-container {

        display: flex;
        flex-direction: column;
        flex: 1;

        .list {
            flex: 1;
            flex-direction: row;
            flex-wrap: wrap;
        }

        .list-banner-wrapper {
            width: 100%;
            height: 107px;
            position: relative;

            .list-banner {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                display: flex;
                flex-direction: row;
                display: flex;

                .list-banner-item {
                    flex-shrink: 0;
                    width: 100%;
                    height: 100%;
                    background-color: var(--tertiary-grouped-background);

                    .image {
                        width: 100%;
                        height: 100%;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                    }
                }
            }
        }
    }
}