.viewer {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: var(--system-black);
    z-index: 1001;
    display: flex;
    align-items: center;
    justify-content: center;

    .image {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
    }

    .close-button {
        position: absolute;
        z-index: 2;
        right: 16px;
        top: calc(16px + env(safe-area-inset-top));
        width: 22px;
        height: 22px;
        background: url(../../assets/image/close-shadow-22x22@2x.png) no-repeat center/ 100%;

        @media screen and (-webkit-min-device-pixel-ratio: 3),
        screen and (min--moz-device-pixel-ratio: 3) {
            background: url(../../assets/image/close-shadow-22x22@3x.png) no-repeat center/ 100%;
        }
    }
}