.header-component {
    height: calc(44px + env(safe-area-inset-top));
    padding-top: env(safe-area-inset-top);
    z-index: 1000;

    .header-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        padding-top: env(safe-area-inset-top);
        height: calc(44px + env(safe-area-inset-top));
        z-index: 1000;
        background-color: var(--background);
        margin: 0 auto;
        max-width: var(--max-screen-width);

        &::before {
            content: '';
            background-color: var(--separator);
            bottom: -1px;
            height: 1px;
            left: 0;
            position: absolute;
            right: 0;
        }

        .title {
            width: calc(100% - 45px - 22px - 16px);
            font-size: var(--headline);
            color: var(--text-primary);
            align-self: center;
            margin: 0 auto;
            font-weight: var(--font-weight-system-semibold);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
        }

        .back-button {
            position: absolute;
            width: 45px;
            height: 100%;
            background-color: var(--text-primary);
            mask: url(./assets/left-15x19@2x.png) no-repeat center/ 15px 19px;

            @media screen and (-webkit-min-device-pixel-ratio: 3),
            screen and (min--moz-device-pixel-ratio: 3) {
                mask: url(./assets/left-15x19@3x.png) no-repeat center/ 15px 19px;
            }
        }
    }
}