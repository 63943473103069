.not-found-page {
    .title {
        margin-top: calc(32px + env(safe-area-inset-top));
        margin-left: 16px;
        margin-right: 16px;
        font-size: var(--title2);
        font-weight: var(--font-weight-system-semibold);
        text-align: center;
    }

    .desc {
        margin-top: 32px;
        margin-left: 16px;
        margin-right: 16px;
        font-size: var(--headline);
        text-align: center;
        line-height: calc(1.58 * var(--headline));

        .go-button {
            color: #00376b;
            font-weight: var(--font-weight-system-medium);
        }
    }
}