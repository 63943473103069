.inbox-page {
    display: flex;
    min-height: 100%;
    flex-direction: column;
    padding-top: calc(44px + env(safe-area-inset-top));
    padding-bottom: calc(49px + env(safe-area-inset-bottom));

    .segmented-control {
        position: fixed;
        z-index: 1000;
        padding-top: env(safe-area-inset-top);
        height: calc(44px + env(safe-area-inset-top));
        width: 100%;
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid var(--separator);
        top: 0px;
        background-color: var(--background);
        max-width: var(--max-screen-width);
        margin: 0 auto;

        .item {
            margin: 0 8px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            &:first-child {
                margin-left: 16px;
            }

            &:last-child {
                margin-right: 16px;
            }

            .label {
                color: var(--text-primary);
                font-weight: var(--font-weight-system-semibold);
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
                border-bottom: 1.5px solid rgba(0, 0, 0, 0);
            }

            &.active .label {
                font-size: var(--title2);
                color: var(--system-pink);
                border-bottom: 1.5px solid var(--system-pink);
            }
        }

        .header-token-view {
            z-index: 2;
            position: absolute;
            top: calc(env(safe-area-inset-top) + 10px);
            right: 16px;
            border-radius: 12px;
            height: 24px;
            background-color: var(--tertiary-grouped-background);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            .token-icon {
                margin-left: 10px;
                margin-right: 5px;
                width: 22px;
                height: 22px;
                background: url(../../../assets/image/token-100x100@2x.png) no-repeat center/ cover;

                @media screen and (-webkit-min-device-pixel-ratio: 3),
                screen and (min--moz-device-pixel-ratio: 3) {
                    background: url(../../../assets/image/token-100x100@3x.png) no-repeat center/ cover;
                }
            }

            .token-label {
                margin-right: 5px;
                color: var(--text-primary);
                font-size: var(--subheadline);
                font-weight: var(--font-weight-system-medium);
            }


            .add-icon {
                width: 22px;
                height: 22px;
                background: url(../../../assets/image/add-round-22x22@2x.png) no-repeat center/ cover;

                @media screen and (-webkit-min-device-pixel-ratio: 3),
                screen and (min--moz-device-pixel-ratio: 3) {
                    background: url(../../../assets/image/add-round-22x22@3x.png) no-repeat center/ cover;
                }
            }
        }
    }

    .page-container {
        display: flex;
        flex-direction: column;
        flex: 1 1;
    }

    .list {
        flex: 1;
        z-index: 1;
    }
}