.list-component {
    display: flex;
    flex-direction: column;
    position: relative;

    .list-footer-view {
        height: calc(64px + env(safe-area-inset-bottom));
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: env(safe-area-inset-bottom);

        .no-more-data {
            font-size: var(--footnote);
            color: var(--text-secondary);
        }
    }
}

.ptr--ptr {
    box-shadow: none !important;
}

.list-loading-view {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.list-error-view {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .error-icon {
        width: 57px;
        height: 52px;
        background: url(../../assets/image/default-error-57x52@2x.png) no-repeat center/ cover;

        @media screen and (-webkit-min-device-pixel-ratio: 3),
        screen and (min--moz-device-pixel-ratio: 3) {
            background: url(../../assets/image/default-error-57x52@3x.png) no-repeat center/ cover;
        }
    }

    .error-title {
        margin-top: 30px;
        font-weight: var(--font-weight-system-medium);
        font-size: var(--subheadline);
        color: var(--text-primary);
    }

    .error-message {
        margin-top: 10px;
        font-weight: var(--font-weight-system-regular);
        font-size: var(--footnote);
        color: var(--text-secondary);
    }

    .error-retry {
        margin-top: 24px;
        font-size: var(--footnote);
        font-weight: var(--font-weight-system-medium);
        color: var(--text-primary);
        border: 1px solid var(--separator);
        padding-left: 32px;
        padding-right: 32px;
        height: 40px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        .error-retry-icon {
            margin-right: 8px;
            width: 12px;
            height: 14px;
            background: var(--text-primary);
            mask: url(../../assets/image/retry-12x14@2x.png) no-repeat center/ cover;

            @media screen and (-webkit-min-device-pixel-ratio: 3),
            screen and (min--moz-device-pixel-ratio: 3) {
                mask: url(../../assets/image/retry-12x14@3x.png) no-repeat center/ cover;
            }
        }
    }
}

.list-empty-view {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .empty-icon {
        width: 47px;
        height: 53px;
        background: url(../../assets/image/default-empty-47x53@2x.png) no-repeat center/ cover;

        @media screen and (-webkit-min-device-pixel-ratio: 3),
        screen and (min--moz-device-pixel-ratio: 3) {
            background: url(../../assets/image/default-empty-47x53@3x.png) no-repeat center/ cover;
        }
    }

    .empty-title {
        margin-top: 30px;
        font-weight: var(--font-weight-system-medium);
        font-size: var(--subheadline);
        color: var(--text-primary);
    }

    .empty-message {
        margin-top: 10px;
        font-weight: var(--font-weight-system-regular);
        font-size: var(--footnote);
        color: var(--text-secondary);
    }
}