.welcome-page {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    .lottie-container {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        z-index: -1;
        overflow: hidden;
    }

    .title-label {
        font-size: var(--largeTitle);
        font-weight: var(--font-weight-system-semibold);
        color: var(--text-primary);
    }

    .title-message {
        font-size: var(--footnote);
        color: var(--text-primary);
        text-align: center;
        margin-top: 10px;
        margin-left: 16px;
        margin-right: 16px;
    }

    .login-button {
        margin-top: 16px;
        color: var(--text-primary);
        border-radius: 22px;
        border: 1px solid var(--text-primary);
        font-size: var(--headline);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: calc(100% - 64px);
        height: 44px;
        font-weight: var(--font-weight-system-semibold);
    }

    .signup-button {
        margin-top: 30px;
        background: var(--system-pink);
        color: var(--system-white);
        font-size: var(--headline);
        border-radius: 22px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: calc(100% - 64px);
        height: 44px;
        font-weight: var(--font-weight-system-semibold);
    }

    .legal-label {
        margin-top: 30px;
        margin-bottom: 16px;
        font-size: var(--caption2);
        margin-left: 16px;
        margin-right: 16px;
        text-align: center;
        margin-bottom: calc(16px + env(safe-area-inset-bottom));

        .underline {
            text-decoration: underline;
        }
    }
}