.recharge-modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1001;
    display: flex;
    align-items: flex-end;
    transition: all .2s ease-in-out;

    .call-page-container {
        width: 100%;
        height: 100%;
        z-index: 1;
        position: absolute;

        .call-page-main {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }

    &.hide {
        opacity: 0;
        pointer-events: none;

        .content-view {
            transform: translateY(20px)
        }
    }

    .mask {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: var(--dialog-mask);
        z-index: 1;
        transition: all .2s ease-in-out;

        &.hide {
            opacity: 0;
            pointer-events: none;
            transform: translateY(20px)
        }
    }

    .content-view {
        z-index: 2;
        background-color: var(--background);
        width: 100%;
        max-width: calc(var(--max-screen-width) + 12px);
        margin: 0 auto;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        height: calc(292px + env(safe-area-inset-bottom));
        padding-bottom: env(safe-area-inset-bottom);
        transition: all .2s ease-in-out;

        &.hide {
            opacity: 0;
            pointer-events: none;
            transform: translateY(20px)
        }

        .content-header {
            height: 56px;
            display: flex;
            position: relative;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .title {
                margin-left: 16px;
                color: var(--text-primary);
                font-size: var(--headline);
                font-weight: var(--font-weight-system-semibold);
            }

            .header-token-view {
                position: absolute;
                right: 16px;
                border-radius: 12px;
                height: 24px;
                background-color: var(--tertiary-grouped-background);
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                .token-icon {
                    margin-left: 10px;
                    margin-right: 5px;
                    width: 16px;
                    height: 16px;
                    background: url(../../assets/image/token-100x100@2x.png) no-repeat center/ cover;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        background: url(../../assets/image/token-100x100@3x.png) no-repeat center/ cover;
                    }
                }

                .token-label {
                    margin-right: 10px;
                    color: var(--text-primary);
                    font-size: var(--subheadline);
                    font-weight: var(--font-weight-system-medium);
                }
            }
        }

        .list {
            padding-top: 20px;
            height: 236px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow-x: scroll;

            .recharge-item-view {
                flex-shrink: 0;
                height: 196px;
                width: 134px;
                margin-right: 8px;
                position: relative;
                border: 1px solid var(--separator);
                border-radius: 6px;

                &:first-child {
                    margin-left: 16px;

                    &::after {
                        content: '';
                        position: absolute;
                        width: 40px;
                        height: 40px;
                        right: 0px;
                        top: -20px;
                        background: url(../../assets/image/recharge-hot-tag-40x40@2x.png) no-repeat center/ cover;
                    }
                }

                .content {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    .token-icon {
                        width: 85px;
                        height: 85px;

                        &.level-1 {
                            background: url(../../assets/image/token-level-1-85x85@2x.png) no-repeat center/ cover;

                            @media screen and (-webkit-min-device-pixel-ratio: 3),
                            screen and (min--moz-device-pixel-ratio: 3) {
                                background: url(../../assets/image/token-level-1-85x85@3x.png) no-repeat center/ cover;
                            }
                        }

                        &.level-2 {
                            background: url(../../assets/image/token-level-2-85x85@2x.png) no-repeat center/ cover;

                            @media screen and (-webkit-min-device-pixel-ratio: 3),
                            screen and (min--moz-device-pixel-ratio: 3) {
                                background: url(../../assets/image/token-level-2-85x85@3x.png) no-repeat center/ cover;
                            }
                        }

                        &.level-3 {
                            background: url(../../assets/image/token-level-3-85x85@2x.png) no-repeat center/ cover;

                            @media screen and (-webkit-min-device-pixel-ratio: 3),
                            screen and (min--moz-device-pixel-ratio: 3) {
                                background: url(../../assets/image/token-level-3-85x85@3x.png) no-repeat center/ cover;
                            }
                        }

                        &.level-4 {
                            background: url(../../assets/image/token-level-4-85x85@2x.png) no-repeat center/ cover;

                            @media screen and (-webkit-min-device-pixel-ratio: 3),
                            screen and (min--moz-device-pixel-ratio: 3) {
                                background: url(../../assets/image/token-level-4-85x85@3x.png) no-repeat center/ cover;
                            }
                        }

                        &.level-5 {
                            background: url(../../assets/image/token-level-5-85x85@2x.png) no-repeat center/ cover;

                            @media screen and (-webkit-min-device-pixel-ratio: 3),
                            screen and (min--moz-device-pixel-ratio: 3) {
                                background: url(../../assets/image/token-level-5-85x85@3x.png) no-repeat center/ cover;
                            }
                        }

                        &.level-6 {
                            background: url(../../assets/image/token-level-6-85x85@2x.png) no-repeat center/ cover;

                            @media screen and (-webkit-min-device-pixel-ratio: 3),
                            screen and (min--moz-device-pixel-ratio: 3) {
                                background: url(../../assets/image/token-level-6-85x85@3x.png) no-repeat center/ cover;
                            }
                        }

                        &.level-7 {
                            background: url(../../assets/image/token-level-7-85x85@2x.png) no-repeat center/ cover;

                            @media screen and (-webkit-min-device-pixel-ratio: 3),
                            screen and (min--moz-device-pixel-ratio: 3) {
                                background: url(../../assets/image/token-level-7-85x85@3x.png) no-repeat center/ cover;
                            }
                        }
                    }

                    .token-label {
                        margin-top: 5px;
                        color: var(--text-primary);
                        font-size: var(--title2);
                        font-weight: var(--font-weight-system-semibold);
                    }

                    .price {
                        color: var(--text-secondary);
                        font-size: var(--caption2);
                        font-weight: var(--font-weight-system-medium);
                    }

                    .extra-token {
                        margin-top: 5px;
                        color: var(--system-white);
                        background-color: var(--system-pink);
                        font-size: var(--caption2);
                        font-weight: var(--font-weight-system-medium);
                        height: 32px;
                        border-radius: 16px;
                        line-height: 32px;
                        padding-left: 16px;
                        padding-right: 41px;
                        position: relative;

                        &::after {
                            content: '';
                            position: absolute;
                            width: 20px;
                            height: 20px;
                            top: 6px;
                            right: 16px;
                            background: url(../../assets/image/token-100x100@2x.png) no-repeat center/ cover;

                            @media screen and (-webkit-min-device-pixel-ratio: 3),
                            screen and (min--moz-device-pixel-ratio: 3) {
                                background: url(../../assets/image/token-100x100@3x.png) no-repeat center/ cover;
                            }
                        }
                    }
                }
            }
        }
    }

    .first-call-recharge-content-view {
        z-index: 3;
        background-color: var(--background);
        width: 100%;
        max-width: calc(var(--max-screen-width) + 12px);
        margin: 0 auto;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        height: calc(260px + env(safe-area-inset-bottom));
        padding-bottom: env(safe-area-inset-bottom);
        transition: all .2s ease-in-out;
        background: rgba(0, 0, 0, 0.7);
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
        border-radius: 15px 15px 0px 0px;
        backdrop-filter: blur(10px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &.hide {
            opacity: 0;
            pointer-events: none;
            transform: translateY(20px)
        }

        .first-call-recharge-head {
            width: 71px;
            height: 71px;
            background: url(../../assets/image/recharge-call-head-71x71@2x.png) no-repeat center/ cover;

            @media screen and (-webkit-min-device-pixel-ratio: 3),
            screen and (min--moz-device-pixel-ratio: 3) {
                background: url(../../assets/image/recharge-call-head-71x71@3x.png) no-repeat center/ cover;
            }
        }

        .first-call-recharge-title {
            margin-top: 20px;
            color: var(--system-white);
            font-size: var(--subheadline);
            font-weight: var(--font-weight-system-medium);
        }

        .first-call-recharge-button {
            margin-top: 40px;
            height: 50px;
            padding-left: 125px;
            padding-right: 125px;
            background: #FFFFFF;
            border-radius: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--system-white);
            color: var(--system-black);
            font-size: var(--subheadline);
            font-weight: var(--font-weight-system-medium);
        }
    }
}