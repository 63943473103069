.direct-page {
    display: flex;
    flex-direction: column;
    padding-bottom: calc(49px + env(safe-area-inset-bottom));

    .header-component {
        .custom-content {
            width: calc(100% - 45px - 22px - 16px);
            font-size: var(--headline);
            color: var(--text-primary);
            align-self: center;
            margin: 0 auto;
            font-weight: var(--font-weight-system-semibold);
            display: flex;
            flex-direction: column;
            align-items: center;

            .custom-title {
                font-size: var(--subheadline);
                color: var(--text-primary);
                font-weight: var(--font-weight-system-semibold);
            }

            .token-price {
                align-self: center;
                display: flex;
                align-items: center;
                justify-content: center;

                .token-icon {
                    width: 12px;
                    height: 12px;
                    margin-right: 4px;
                    background: url(../../../assets/image/token-100x100@2x.png) no-repeat center/ 100%;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        background: url(../../../assets/image/token-100x100@3x.png) no-repeat center/ 100%;
                    }
                }

                .token-label {
                    color: var(--text-secondary);
                    font-size: var(--caption2);
                }
            }
        }
    }

    .list-loading-view {
        background-color: var(--background);
        z-index: 3;
    }

    .call-button {
        width: 22px;
        height: 22px;
        position: absolute;
        background-color: var(--text-primary);
        right: 16px;
        mask: url(../../../assets/image/call-video-22x22@2x.png) no-repeat center/ cover;

        @media screen and (-webkit-min-device-pixel-ratio: 3),
        screen and (min--moz-device-pixel-ratio: 3) {
            mask: url(../../../assets/image/call-video-22x22@3x.png) no-repeat center/ cover;
        }
    }

    .proposale-view {
        height: 44px;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 2;
        display: flex;
        flex-direction: row;
        bottom: calc(65px + env(safe-area-inset-bottom));
        overflow-x: scroll;
        margin: 0 auto;
        max-width: var(--max-screen-width);

        &::-webkit-scrollbar {
            display: none;
        }

        .proposale-item {
            margin-left: 5px;
            margin-right: 5px;
            padding-left: 10px;
            padding-right: 10px;
            display: flex;
            flex-direction: row;
            border-radius: 6px;
            border: 1px solid var(--separator);
            align-items: center;

            &:nth-child(1) {
                margin-left: 16px;
            }

            .proposale-item-label {
                color: var(--text-secondary);
                font-size: var(--footnote);
                word-wrap: normal;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                white-space: nowrap;
                margin-right: 10px;
            }

            .proposale-item-button {
                width: 22px;
                height: 22px;
                border-radius: 11px;
                background-color: var(--system-pink);
                position: relative;
                flex-shrink: 0;

                &::after {
                    position: absolute;
                    top: 6px;
                    left: 6px;
                    content: '';
                    display: block;
                    width: 10px;
                    height: 10px;
                    background-color: var(--system-white);
                    mask: url(../../../assets/image/arrow-top-10x10@2x.png) no-repeat center/ 10px 10px;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        mask: url(../../../assets/image/arrow-top-10x10@3x.png) no-repeat center/ 10px 10px;
                    }
                }
            }
        }
    }

    .footer-view {
        background-color: var(--background);
        border: 0;
        height: calc(49px + env(safe-area-inset-bottom));
        padding-bottom: env(safe-area-inset-bottom);
        left: 0;
        position: fixed;
        right: 0;
        z-index: 2;
        bottom: 0;
        display: flex;
        align-items: center;
        margin: 0 auto;
        max-width: var(--max-screen-width);

        .input {
            margin-left: 16px;
            height: 40px;
            flex: 1;
            padding: 0 16px;
            border: 1px solid var(--separator);
            border-radius: 20px;
            font-size: var(--subheadline);
            color: var(--text-primary);
        }

        .image-button {
            width: 54px;
            height: 44px;
            background-color: var(--text-primary);
            position: relative;
            mask: url(../../../assets/image/picture-22x22@2x.png) no-repeat 16px center/ 22px 22px;

            @media screen and (-webkit-min-device-pixel-ratio: 3),
            screen and (min--moz-device-pixel-ratio: 3) {
                mask: url(../../../assets/image/picture-22x22@3x.png) no-repeat 16px center/ 22px 22px;
            }


            .picker {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 0;
            }
        }
    }

    .list-view {
        flex-direction: column;
        padding-bottom: 16px;

        .list-item-view {
            display: flex;
            margin-top: 16px;
            margin-left: 16px;
            margin-right: 16px;
            flex-direction: column;

            .time-label {
                font-size: var(--caption2);
                font-weight: var(--font-weight-system-regular);
                text-align: center;
                color: var(--text-secondary);
                margin-bottom: 16px;
            }

            .content {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                &.peer {
                    justify-content: flex-start;
                }

                .resend-button {
                    margin-right: 10px;
                    width: 12px;
                    height: 14px;
                    background-color: var(--system-red);
                    mask: url(../../../assets/image/resend-12x14@2x.png) no-repeat center/ 100%;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        mask: url(../../../assets/image/resend-12x14@3x.png) no-repeat center/ 100%;
                    }
                }

                .avatar-left {
                    width: 35px;
                    height: 35px;
                    border-radius: 17.5px;
                    margin-right: 10px;
                }

                .avatar-right {
                    width: 35px;
                    height: 35px;
                    border-radius: 17.5px;
                    margin-left: 10px;
                }

                .text-main-container {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    background-color: var(--tertiary-grouped-background);
                    display: flex;
                    flex-direction: column;
                    border-radius: 6px;
                    max-width: 215px;

                    .text-main {
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        .call-miss {
                            width: 16px;
                            height: 16px;
                            margin-left: 10px;
                            background-color: var(--text-primary);
                            mask: url(../../../assets/image/call-miss-16x16@2x.png) no-repeat center/ 100%;

                            @media screen and (-webkit-min-device-pixel-ratio: 3),
                            screen and (min--moz-device-pixel-ratio: 3) {
                                mask: url(../../../assets/image/call-miss-16x16@3x.png) no-repeat center/ 100%;
                            }
                        }

                        .text-label {
                            margin-left: 10px;
                            margin-right: 10px;
                            font-size: var(--subheadline);
                            font-weight: var(--font-weight-system-regular);
                            word-wrap: break-word;
                            word-break: normal;
                        }

                        .spinner {
                            margin-right: 10px;
                        }
                    }

                    .spinner {
                        margin-top: 10px;
                        margin-left: 10px;
                        margin-right: 10px;
                    }

                    .translate-button {
                        cursor: pointer;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding-left: 10px;
                        padding-top: 5px;
                        padding-right: 10px;

                        .icon {
                            width: 16px;
                            height: 16px;
                            background-color: var(--system-royal);
                            mask: url(../../../assets/image/translate-16x16@2x.png) no-repeat center/ 100%;

                            @media screen and (-webkit-min-device-pixel-ratio: 3),
                            screen and (min--moz-device-pixel-ratio: 3) {
                                mask: url(../../../assets/image/translate-16x16@3x.png) no-repeat center/ 100%;
                            }
                        }

                        .label {
                            color: var(--system-royal);
                            margin-left: 5px;
                            font-size: var(--subheadline);
                        }
                    }
                }

                .image-main-container {
                    border-radius: 6px;
                    width: 105px;
                    height: 140px;
                    overflow: hidden;

                    .image {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        background-color: var(--tertiary-grouped-background);
                    }
                }

                .voice-main-container {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    border-radius: 6px;
                    max-width: 215px;
                    background-color: var(--tertiary-grouped-background);
                }
            }
        }
    }
}