.anchor-cell {
    position: relative;
    margin-top: 1px;
    width: calc(50% - 1px);
    height: 0;
    padding-bottom: 50%;
    background-color: var(--tertiary-grouped-background);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &:nth-child(odd) {
        margin-right: 1px;
    }

    .image {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .mask {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    }

    .bottom-content {
        position: absolute;
        z-index: 3;
        bottom: 6px;
        left: 10px;
        right: 10px;
    }

    .nickname-label {
        color: var(--system-white);
        font-size: var(--footnote);
        overflow: hidden;
        word-break: normal;
        word-wrap: normal;
        text-overflow: ellipsis;
    }

    .rate-view {
        position: absolute;
        z-index: 3;
        top: 10px;
        right: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .rate-image {
            width: 12px;
            height: 12px;
            margin-right: 5px;

            background: url(../../assets/image/start-white-12x12@2x.png) no-repeat center/ 100%;

            @media screen and (-webkit-min-device-pixel-ratio: 3),
            screen and (min--moz-device-pixel-ratio: 3) {
                background: url(../../assets/image/start-white-12x12@3x.png) no-repeat center/ 100%;
            }
        }

        .rate-label {
            color: var(--system-white);
            font-size: var(--footnote);
        }
    }

}