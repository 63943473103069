.recharge-page {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    padding-top: calc(44px + env(safe-area-inset-top));
    padding-bottom: calc(49px + env(safe-area-inset-bottom));

    .page-loading {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
    }

    .header {
        position: fixed;
        padding-top: env(safe-area-inset-top);
        height: calc(44px + env(safe-area-inset-top));
        width: 100%;
        max-width: var(--max-screen-width);
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        z-index: 1000;
        top: 0;
        background-color: var(--background);

        .title {
            margin-left: 16px;
            font-size: var(--title2);
            font-weight: var(--font-weight-system-semibold);
        }

        .header-token-view {
            position: absolute;
            right: 16px;
            border-radius: 12px;
            height: 24px;
            background-color: var(--tertiary-grouped-background);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            .token-icon {
                margin-left: 10px;
                margin-right: 5px;
                width: 22px;
                height: 22px;
                background: url(../../../assets/image/token-100x100@2x.png) no-repeat center/ cover;

                @media screen and (-webkit-min-device-pixel-ratio: 3),
                screen and (min--moz-device-pixel-ratio: 3) {
                    background: url(../../../assets/image/token-100x100@3x.png) no-repeat center/ cover;
                }
            }

            .token-label {
                margin-right: 10px;
                color: var(--text-primary);
                font-size: var(--subheadline);
                font-weight: var(--font-weight-system-medium);
            }
        }
    }

    .banner {
        width: 100%;
        height: 0;
        padding-bottom: 34.66%;
        position: relative;

        &:after {
            content: "";
            display: block;
            visibility: hidden;
            clear: both;
        }

        .banner-image {
            position: absolute;
            width: 100%;
            height: 100%;
            background: url(../../../assets/image/recharge-banner-375x130@2x.png) no-repeat center/ cover;

            @media screen and (-webkit-min-device-pixel-ratio: 3),
            screen and (min--moz-device-pixel-ratio: 3) {
                background: url(../../../assets/image/recharge-banner-375x130@3x.png) no-repeat center/ cover;
            }
        }

        .banner-header {
            position: absolute;
            background-color: var(--background);
            height: 49px;
            bottom: -24.5px;
            left: 0;
            right: 0;
            border-top-left-radius: 14px;
            border-top-right-radius: 14px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid var(--separator);

            .shop {
                margin-left: 16px;
                width: 40px;
                height: 40px;
                background: url(../../../assets/image/shop-40x40@2x.png) no-repeat center/ cover;

                @media screen and (-webkit-min-device-pixel-ratio: 3),
                screen and (min--moz-device-pixel-ratio: 3) {
                    background: url(../../../assets/image/shop-40x40@3x.png) no-repeat center/ cover;
                }
            }

            .banner-title {
                margin-left: 10px;
                color: var(--text-primary);
                font-size: var(--headline);
                font-weight: var(--font-weight-system-semibold);
            }
        }

        .record {
            position: absolute;
            top: 16px;
            right: 0px;
            line-height: 20px;
            height: 20px;
            padding: 0 16px;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            color: var(--system-white);
            font-size: var(--subheadline);
            background-color: rgba(0, 0, 0, 0.16);
        }
    }

    .list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 45px;
        align-items: center;
        justify-content: space-between;

        .recharge-item-view {
            flex-shrink: 0;
            margin-bottom: 10px;
            width: calc((100% - 42px) / 2);
            height: 196px;
            position: relative;
            border: 1px solid var(--separator);
            border-radius: 6px;
            background-color: var(--background-light);

            &:nth-child(odd) {
                margin-left: 16px;
            }

            &:nth-child(even) {
                margin-right: 16px;
            }

            &:first-child {
                &::after {
                    content: '';
                    position: absolute;
                    width: 40px;
                    height: 40px;
                    right: 0px;
                    top: -20px;
                    background: url(../../../assets/image/recharge-hot-tag-40x40@2x.png) no-repeat center/ cover;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        background: url(../../../assets/image/recharge-hot-tag-40x40@3x.png) no-repeat center/ cover;
                    }
                }
            }

            .content {
                position: absolute;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .token-icon {
                    width: 85px;
                    height: 85px;

                    &.level-1 {
                        background: url(../../../assets/image/token-level-1-85x85@2x.png) no-repeat center/ cover;

                        @media screen and (-webkit-min-device-pixel-ratio: 3),
                        screen and (min--moz-device-pixel-ratio: 3) {
                            background: url(../../../assets/image/token-level-1-85x85@3x.png) no-repeat center/ cover;
                        }
                    }

                    &.level-2 {
                        background: url(../../../assets/image/token-level-2-85x85@2x.png) no-repeat center/ cover;

                        @media screen and (-webkit-min-device-pixel-ratio: 3),
                        screen and (min--moz-device-pixel-ratio: 3) {
                            background: url(../../../assets/image/token-level-2-85x85@3x.png) no-repeat center/ cover;
                        }
                    }

                    &.level-3 {
                        background: url(../../../assets/image/token-level-3-85x85@2x.png) no-repeat center/ cover;

                        @media screen and (-webkit-min-device-pixel-ratio: 3),
                        screen and (min--moz-device-pixel-ratio: 3) {
                            background: url(../../../assets/image/token-level-3-85x85@3x.png) no-repeat center/ cover;
                        }
                    }

                    &.level-4 {
                        background: url(../../../assets/image/token-level-4-85x85@2x.png) no-repeat center/ cover;

                        @media screen and (-webkit-min-device-pixel-ratio: 3),
                        screen and (min--moz-device-pixel-ratio: 3) {
                            background: url(../../../assets/image/token-level-4-85x85@3x.png) no-repeat center/ cover;
                        }
                    }

                    &.level-5 {
                        background: url(../../../assets/image/token-level-5-85x85@2x.png) no-repeat center/ cover;

                        @media screen and (-webkit-min-device-pixel-ratio: 3),
                        screen and (min--moz-device-pixel-ratio: 3) {
                            background: url(../../../assets/image/token-level-5-85x85@3x.png) no-repeat center/ cover;
                        }
                    }

                    &.level-6 {
                        background: url(../../../assets/image/token-level-6-85x85@2x.png) no-repeat center/ cover;

                        @media screen and (-webkit-min-device-pixel-ratio: 3),
                        screen and (min--moz-device-pixel-ratio: 3) {
                            background: url(../../../assets/image/token-level-6-85x85@3x.png) no-repeat center/ cover;
                        }
                    }

                    &.level-7 {
                        background: url(../../../assets/image/token-level-7-85x85@2x.png) no-repeat center/ cover;

                        @media screen and (-webkit-min-device-pixel-ratio: 3),
                        screen and (min--moz-device-pixel-ratio: 3) {
                            background: url(../../../assets/image/token-level-7-85x85@3x.png) no-repeat center/ cover;
                        }
                    }
                }

                .token-label {
                    margin-top: 5px;
                    color: var(--text-primary);
                    font-size: var(--title2);
                    font-weight: var(--font-weight-system-semibold);
                }

                .price {
                    color: var(--text-secondary);
                    font-size: var(--caption2);
                    font-weight: var(--font-weight-system-medium);
                }

                .extra-token {
                    margin-top: 5px;
                    color: var(--system-white);
                    background-color: var(--system-pink);
                    font-size: var(--caption2);
                    font-weight: var(--font-weight-system-medium);
                    height: 32px;
                    border-radius: 16px;
                    line-height: 32px;
                    padding-left: 16px;
                    padding-right: 41px;
                    position: relative;

                    &::after {
                        content: '';
                        position: absolute;
                        width: 20px;
                        height: 20px;
                        top: 6px;
                        right: 16px;
                        background: url(../../../assets/image/token-100x100@2x.png) no-repeat center/ cover;

                        @media screen and (-webkit-min-device-pixel-ratio: 3),
                        screen and (min--moz-device-pixel-ratio: 3) {
                            background: url(../../../assets/image/token-100x100@3x.png) no-repeat center/ cover;
                        }
                    }
                }
            }
        }
    }

    .earn-container {
        .earn-title {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            .line {
                margin-left: 16px;
                margin-right: 16px;
                height: 1px;
                flex: 1;
                background-color: var(--separator);
            }

            .label {
                font-size: var(--headline);
                font-weight: var(--font-weight-system-medium);
                text-align: center;
                margin-top: 16px;
                margin-bottom: 16px;
            }

            .line {
                height: 1px;
                flex: 1;
            }
        }

        .bind-email-item-view {
            display: block;
            margin-left: 16px;
            flex-shrink: 0;
            margin-bottom: 10px;
            width: calc((100% - 42px) / 2);
            height: 196px;
            position: relative;
            border: 1px solid var(--separator);
            border-radius: 6px;
            background-color: var(--background-light);

            .content {
                position: absolute;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .icon-view {
                    width: 58px;
                    height: 58px;
                    background: url(../../../assets/image/bind-email-58x58@2x.png) no-repeat center/ cover;

                    @media screen and (-webkit-min-device-pixel-ratio: 3),
                    screen and (min--moz-device-pixel-ratio: 3) {
                        background: url(../../../assets/image/bind-email-58x58@3x.png) no-repeat center/ cover;
                    }
                }

                .token-view {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .token-icon {
                        width: 22px;
                        height: 22px;
                        margin-right: 8px;
                        background: url(../../../assets/image/token-100x100@2x.png) no-repeat center/ cover;

                        @media screen and (-webkit-min-device-pixel-ratio: 3),
                        screen and (min--moz-device-pixel-ratio: 3) {
                            background: url(../../../assets/image/token-100x100@3x.png) no-repeat center/ cover;
                        }
                    }

                    .token-label {
                        color: var(--text-primary);
                        font-size: var(--title2);
                        font-weight: var(--font-weight-system-semibold);
                    }
                }

                .bind-title {
                    font-size: var(--caption2);
                    font-weight: var(--font-weight-system-medium);
                }

                .go-button {
                    margin-top: 5px;
                    color: var(--system-white);
                    background-color: var(--system-pink);
                    font-size: var(--caption2);
                    font-weight: var(--font-weight-system-medium);
                    height: 32px;
                    border-radius: 16px;
                    line-height: 32px;
                    padding-left: 32px;
                    padding-right: 32px;
                    position: relative;
                }

            }
        }
    }
}