.black-list-page {
    display: flex;
    flex-direction: column;
    min-height: 100%;

    .list {
        flex: 1;
    }

    .black-list-cell-item {
        height: 55px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        .avatar {
            width: 35px;
            height: 35px;
            border-radius: 17.5px;
            left: 16px;
            margin-left: 16px;
            z-index: 2;
            pointer-events: none;
        }

        .nickname-wrapper {
            margin-left: 16px;
            display: flex;
            flex: 1;
            overflow: hidden;
            z-index: 2;
            pointer-events: none;

            .nickname {
                font-size: var(--subheadline);
                font-weight: var(--font-weight-system-semibold);
                color: var(--text-primary);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .touch {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        .remove-button {
            z-index: 2;
            border: 1px solid var(--separator);
            border-radius: 6px;
            width: 70px;
            height: 24px;
            font-size: var(--footnote);
            color: var(--text-primary);
            margin-left: 8px;
            margin-right: 16px;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

}