.loading-component {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: block;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .content {
        min-height: 100px;
        min-width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px;
        background-color: rgba(38, 38, 38, 1);
        border-radius: 8px;
        color: var(--system-white);
        font-size: var(--footnote);
        transition: opacity .2s ease-in-out;

        &.hide {
            opacity: 0;
        }
    }
}